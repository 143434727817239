/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const inviteAdmin = /* GraphQL */ `
  mutation InviteAdmin($input: InviteAdminInput!) {
    inviteAdmin(input: $input) {
      id
      userId
      tenantId
      firstName
      lastName
      isAdmin
      hidden
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const inviteSupport = /* GraphQL */ `
  mutation InviteSupport($input: InviteAdminInput!) {
    inviteSupport(input: $input) {
      id
      userId
      tenantId
      firstName
      lastName
      isAdmin
      hidden
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const inviteUser = /* GraphQL */ `
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      id
      userId
      tenantId
      firstName
      lastName
      isAdmin
      hidden
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const removeUser = /* GraphQL */ `
  mutation RemoveUser($input: RemoveUserInput!) {
    removeUser(input: $input) {
      id
      externalId
      name
      firstName
      lastName
      email
      showTutorial
      invited
      verified
      fontSize
      language
      receiveEmailNotification
      theme
      useMfa
      customId
      tenantId
      isAdmin
      createdAt
      updatedAt
      groups {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenants {
        items {
          id
          userId
          tenantId
          isAdmin
          license
          hidden
          name
          firstName
          lastName
          configuration {
            projectFilter {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
          }
          customId
          active
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const resendVerificationMail = /* GraphQL */ `
  mutation ResendVerificationMail($input: ResendVerificationMailInput!) {
    resendVerificationMail(input: $input) {
      id
      externalId
      name
      firstName
      lastName
      email
      showTutorial
      invited
      verified
      fontSize
      language
      receiveEmailNotification
      theme
      useMfa
      customId
      tenantId
      isAdmin
      createdAt
      updatedAt
      groups {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenants {
        items {
          id
          userId
          tenantId
          isAdmin
          license
          hidden
          name
          firstName
          lastName
          configuration {
            projectFilter {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
          }
          customId
          active
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const removeAllReports = /* GraphQL */ `
  mutation RemoveAllReports($input: RemoveAllReportsInput!) {
    removeAllReports(input: $input) {
      reportIds
      count
    }
  }
`;
export const verifyEmail = /* GraphQL */ `
  mutation VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      isVerified
    }
  }
`;
export const syncAll = /* GraphQL */ `
  mutation SyncAll {
    syncAll
  }
`;
export const syncTenant = /* GraphQL */ `
  mutation SyncTenant($input: SyncTenantInput!) {
    syncTenant(input: $input)
  }
`;
export const syncObject = /* GraphQL */ `
  mutation SyncObject($input: SyncObjectInput!) {
    syncObject(input: $input)
  }
`;
export const generateProject = /* GraphQL */ `
  mutation GenerateProject($input: GenerateProjectInput!) {
    generateProject(input: $input) {
      id
      groupId
      quoteId
      userId
      managerId
      managerName
      quoterId
      quoterName
      operatorId
      operatorName
      accountId
      accountName
      contactId
      contactName
      name
      status
      inquiryDate
      quotationDate
      destinationName
      destination {
        lat
        lng
      }
      confidence
      description
      alert
      cancelled
      cancelReason
      caseMarkNumImgs
      stockNumImgs
      reference
      photoPhases {
        id
        name
        requiresPhoto
        numImgs
      }
      type
      archived
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      schedules {
        items {
          id
          projectId
          name
          m3
          case
          shipType
          stockingDate
          packagingDate
          shippingDate
          cutDate
          numImgs
          shipSubFolders {
            id
            name
            numImgs
          }
          stockNumImgs
          stockSubFolders {
            id
            name
            numImgs
          }
          isShipped
          note
          createdAt
          updatedAt
          products {
            items {
              id
              projectId
              packageTypeId
              scheduleId
              userId
              pricingId
              overwriteUnitPrice
              overwriteUnit
              packageTypeName
              quoteLineId
              quoteLineName
              unit
              name
              order
              productNumber
              quantity
              material
              panelM2
              woodM3
              tareWeight
              grossWeight
              m3
              stamp
              destination
              packagingStart
              packagingEnd
              packageMethod
              description
              readyToPack
              note
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              createdAt
              updatedAt
            }
            nextToken
          }
          project {
            id
            groupId
            quoteId
            userId
            managerId
            managerName
            quoterId
            quoterName
            operatorId
            operatorName
            accountId
            accountName
            contactId
            contactName
            name
            status
            inquiryDate
            quotationDate
            destinationName
            destination {
              lat
              lng
            }
            confidence
            description
            alert
            cancelled
            cancelReason
            caseMarkNumImgs
            stockNumImgs
            reference
            photoPhases {
              id
              name
              requiresPhoto
              numImgs
            }
            type
            archived
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            schedules {
              nextToken
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const copyProject = /* GraphQL */ `
  mutation CopyProject($input: CopyProjectInput!) {
    copyProject(input: $input) {
      id
      groupId
      quoteId
      userId
      managerId
      managerName
      quoterId
      quoterName
      operatorId
      operatorName
      accountId
      accountName
      contactId
      contactName
      name
      status
      inquiryDate
      quotationDate
      destinationName
      destination {
        lat
        lng
      }
      confidence
      description
      alert
      cancelled
      cancelReason
      caseMarkNumImgs
      stockNumImgs
      reference
      photoPhases {
        id
        name
        requiresPhoto
        numImgs
      }
      type
      archived
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      schedules {
        items {
          id
          projectId
          name
          m3
          case
          shipType
          stockingDate
          packagingDate
          shippingDate
          cutDate
          numImgs
          shipSubFolders {
            id
            name
            numImgs
          }
          stockNumImgs
          stockSubFolders {
            id
            name
            numImgs
          }
          isShipped
          note
          createdAt
          updatedAt
          products {
            items {
              id
              projectId
              packageTypeId
              scheduleId
              userId
              pricingId
              overwriteUnitPrice
              overwriteUnit
              packageTypeName
              quoteLineId
              quoteLineName
              unit
              name
              order
              productNumber
              quantity
              material
              panelM2
              woodM3
              tareWeight
              grossWeight
              m3
              stamp
              destination
              packagingStart
              packagingEnd
              packageMethod
              description
              readyToPack
              note
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              createdAt
              updatedAt
            }
            nextToken
          }
          project {
            id
            groupId
            quoteId
            userId
            managerId
            managerName
            quoterId
            quoterName
            operatorId
            operatorName
            accountId
            accountName
            contactId
            contactName
            name
            status
            inquiryDate
            quotationDate
            destinationName
            destination {
              lat
              lng
            }
            confidence
            description
            alert
            cancelled
            cancelReason
            caseMarkNumImgs
            stockNumImgs
            reference
            photoPhases {
              id
              name
              requiresPhoto
              numImgs
            }
            type
            archived
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            schedules {
              nextToken
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const removeProject = /* GraphQL */ `
  mutation RemoveProject($input: RemoveProjectInput!) {
    removeProject(input: $input) {
      id
      groupId
      quoteId
      userId
      managerId
      managerName
      quoterId
      quoterName
      operatorId
      operatorName
      accountId
      accountName
      contactId
      contactName
      name
      status
      inquiryDate
      quotationDate
      destinationName
      destination {
        lat
        lng
      }
      confidence
      description
      alert
      cancelled
      cancelReason
      caseMarkNumImgs
      stockNumImgs
      reference
      photoPhases {
        id
        name
        requiresPhoto
        numImgs
      }
      type
      archived
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      schedules {
        items {
          id
          projectId
          name
          m3
          case
          shipType
          stockingDate
          packagingDate
          shippingDate
          cutDate
          numImgs
          shipSubFolders {
            id
            name
            numImgs
          }
          stockNumImgs
          stockSubFolders {
            id
            name
            numImgs
          }
          isShipped
          note
          createdAt
          updatedAt
          products {
            items {
              id
              projectId
              packageTypeId
              scheduleId
              userId
              pricingId
              overwriteUnitPrice
              overwriteUnit
              packageTypeName
              quoteLineId
              quoteLineName
              unit
              name
              order
              productNumber
              quantity
              material
              panelM2
              woodM3
              tareWeight
              grossWeight
              m3
              stamp
              destination
              packagingStart
              packagingEnd
              packageMethod
              description
              readyToPack
              note
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              createdAt
              updatedAt
            }
            nextToken
          }
          project {
            id
            groupId
            quoteId
            userId
            managerId
            managerName
            quoterId
            quoterName
            operatorId
            operatorName
            accountId
            accountName
            contactId
            contactName
            name
            status
            inquiryDate
            quotationDate
            destinationName
            destination {
              lat
              lng
            }
            confidence
            description
            alert
            cancelled
            cancelReason
            caseMarkNumImgs
            stockNumImgs
            reference
            photoPhases {
              id
              name
              requiresPhoto
              numImgs
            }
            type
            archived
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            schedules {
              nextToken
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const copyQuote = /* GraphQL */ `
  mutation CopyQuote($input: CopyQuoteInput!) {
    copyQuote(input: $input) {
      id
      projectId
      name
      quoteNumber
      linkProduct
      order
      orderPlaced
      packRevenue
      packEstimatedCost
      packEstimatedProfit
      packTotalNetWeight
      packTotalNumOfCases
      packTotalGrossWeight
      packTotalM3
      revenue
      estimatedCost
      estimatedProfit
      totalNumOfCases
      totalNetWeight
      totalGrossWeight
      totalM3
      greeting
      paymentMethod
      destination
      dueDate
      expiryDate
      description
      groupName
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      createdAt
      updatedAt
      quoteLines {
        items {
          id
          quoteId
          pricingId
          packageTypeId
          productId
          quoteProductId
          productName
          pricingName
          packageTypeName
          showInQuoteSummary
          name
          index
          notes
          quantity
          autocalculate
          amount
          minimumAmount
          actualAmount
          order
          unitNW
          NW
          productLength
          productWidth
          productHeight
          outerLength
          outerWidth
          outerHeight
          estimatedUnitM3
          estimatedM3
          estimatedUnitGrossWeight
          estimatedGrossWeight
          estimatedUnitCost
          estimatedCost
          estimatedProfit
          estimatedWood
          estimatedLvl
          estimatedPanel
          estimatedInterior
          estimatedExterior
          estimatedCostM3
          estimatedOther
          unitPrice
          subtotal
          total
          unit
          taxType
          taxRate
          salesType
          costDetail
          createdAt
          updatedAt
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const copyProduct = /* GraphQL */ `
  mutation CopyProduct($input: CopyProductInput!) {
    copyProduct(input: $input) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const copyNonPackProduct = /* GraphQL */ `
  mutation CopyNonPackProduct($input: CopyProductInput!) {
    copyNonPackProduct(input: $input) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const copyPackageType = /* GraphQL */ `
  mutation CopyPackageType($input: CopyPackageTypeInput!) {
    copyPackageType(input: $input) {
      id
      groupId
      pricingId
      name
      description
      publish
      quantity
      material
      stamp
      packageMethod
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      panelM2
      woodM3
      tareWeight
      grossWeight
      note
      standard
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      active
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      areas {
        items {
          id
          packageTypeId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              baseAreaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          packageTypeId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const copyReport = /* GraphQL */ `
  mutation CopyReport($input: CopyReportInput!) {
    copyReport(input: $input) {
      id
      projectId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const generateQuote = /* GraphQL */ `
  mutation GenerateQuote($input: GenerateQuoteInput!) {
    generateQuote(input: $input) {
      id
      projectId
      name
      quoteNumber
      linkProduct
      order
      orderPlaced
      packRevenue
      packEstimatedCost
      packEstimatedProfit
      packTotalNetWeight
      packTotalNumOfCases
      packTotalGrossWeight
      packTotalM3
      revenue
      estimatedCost
      estimatedProfit
      totalNumOfCases
      totalNetWeight
      totalGrossWeight
      totalM3
      greeting
      paymentMethod
      destination
      dueDate
      expiryDate
      description
      groupName
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      createdAt
      updatedAt
      quoteLines {
        items {
          id
          quoteId
          pricingId
          packageTypeId
          productId
          quoteProductId
          productName
          pricingName
          packageTypeName
          showInQuoteSummary
          name
          index
          notes
          quantity
          autocalculate
          amount
          minimumAmount
          actualAmount
          order
          unitNW
          NW
          productLength
          productWidth
          productHeight
          outerLength
          outerWidth
          outerHeight
          estimatedUnitM3
          estimatedM3
          estimatedUnitGrossWeight
          estimatedGrossWeight
          estimatedUnitCost
          estimatedCost
          estimatedProfit
          estimatedWood
          estimatedLvl
          estimatedPanel
          estimatedInterior
          estimatedExterior
          estimatedCostM3
          estimatedOther
          unitPrice
          subtotal
          total
          unit
          taxType
          taxRate
          salesType
          costDetail
          createdAt
          updatedAt
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const importInvoiceLines = /* GraphQL */ `
  mutation ImportInvoiceLines($input: ImportInvoiceLinesInput!) {
    importInvoiceLines(input: $input) {
      id
      externalId
      projectId
      accountId
      contactId
      userId
      groupId
      order
      name
      invoiceNo
      issueDate
      billed
      billingDate
      description
      subtotal
      nonTaxableTotal
      taxableSubtotal
      taxTotal
      taxableTotal
      total
      accountName
      accountSite
      billingType
      accountAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      contactName
      contactFirstName
      contactLastName
      contactDepartment
      contactTitle
      groupName
      logoUrl
      groupBank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      taxRate
      url
      syncLogId
      syncedAt
      syncStatus
      totalPurchasingCost
      grossProfit
      grossProfitMargin
      recordingDate
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      invoiceLines {
        items {
          id
          externalId
          invoiceId
          vendorId
          vendorName
          vendorAccountCode
          vendorSite
          pricingId
          pricingName
          quoteLineId
          name
          shippingDate
          salesType
          order
          m3
          grossWeight
          quantity
          rt
          amount
          minimumAmount
          actualAmount
          unit
          unitPrice
          taxRate
          taxTotal
          subtotal
          total
          taxType
          sfTaxType
          synced
          description
          unitPurchasingCost
          totalPurchasingCost
          createdAt
          updatedAt
          invoice {
            id
            externalId
            projectId
            accountId
            contactId
            userId
            groupId
            order
            name
            invoiceNo
            issueDate
            billed
            billingDate
            description
            subtotal
            nonTaxableTotal
            taxableSubtotal
            taxTotal
            taxableTotal
            total
            accountName
            accountSite
            billingType
            accountAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            contactName
            contactFirstName
            contactLastName
            contactDepartment
            contactTitle
            groupName
            logoUrl
            groupBank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            phone
            fax
            taxRate
            url
            syncLogId
            syncedAt
            syncStatus
            totalPurchasingCost
            grossProfit
            grossProfitMargin
            recordingDate
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            invoiceLines {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const syncInvoice = /* GraphQL */ `
  mutation SyncInvoice($input: SyncInvoiceInput!) {
    syncInvoice(input: $input) {
      id
      externalId
      projectId
      accountId
      contactId
      userId
      groupId
      order
      name
      invoiceNo
      issueDate
      billed
      billingDate
      description
      subtotal
      nonTaxableTotal
      taxableSubtotal
      taxTotal
      taxableTotal
      total
      accountName
      accountSite
      billingType
      accountAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      contactName
      contactFirstName
      contactLastName
      contactDepartment
      contactTitle
      groupName
      logoUrl
      groupBank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      taxRate
      url
      syncLogId
      syncedAt
      syncStatus
      totalPurchasingCost
      grossProfit
      grossProfitMargin
      recordingDate
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      invoiceLines {
        items {
          id
          externalId
          invoiceId
          vendorId
          vendorName
          vendorAccountCode
          vendorSite
          pricingId
          pricingName
          quoteLineId
          name
          shippingDate
          salesType
          order
          m3
          grossWeight
          quantity
          rt
          amount
          minimumAmount
          actualAmount
          unit
          unitPrice
          taxRate
          taxTotal
          subtotal
          total
          taxType
          sfTaxType
          synced
          description
          unitPurchasingCost
          totalPurchasingCost
          createdAt
          updatedAt
          invoice {
            id
            externalId
            projectId
            accountId
            contactId
            userId
            groupId
            order
            name
            invoiceNo
            issueDate
            billed
            billingDate
            description
            subtotal
            nonTaxableTotal
            taxableSubtotal
            taxTotal
            taxableTotal
            total
            accountName
            accountSite
            billingType
            accountAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            contactName
            contactFirstName
            contactLastName
            contactDepartment
            contactTitle
            groupName
            logoUrl
            groupBank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            phone
            fax
            taxRate
            url
            syncLogId
            syncedAt
            syncStatus
            totalPurchasingCost
            grossProfit
            grossProfitMargin
            recordingDate
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            invoiceLines {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createProductBom = /* GraphQL */ `
  mutation CreateProductBom($input: CreateProductBomInput!) {
    createProductBom(input: $input) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const updateProductBom = /* GraphQL */ `
  mutation UpdateProductBom($input: UpdateProductBomInput!) {
    updateProductBom(input: $input) {
      product {
        id
        packageTypeId
        packageTypeName
        quoteLineId
        quoteLineName
        unit
        name
        productNumber
        quantity
        description
        note
        panelM2
        woodM3
        tareWeight
        grossWeight
        material
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        elements
      }
      components {
        areaId
        areaName
        areaOrder
        id
        name
        slug
        order
        elements
      }
      interiors {
        id
        name
        type
        slug
        unit
        order
        elements
      }
      exteriors {
        id
        name
        type
        slug
        unit
        order
        elements
      }
    }
  }
`;
export const updatePackageBom = /* GraphQL */ `
  mutation UpdatePackageBom($input: UpdateProductBomInput!) {
    updatePackageBom(input: $input) {
      product {
        id
        name
        quantity
        description
        note
        panelM2
        woodM3
        tareWeight
        grossWeight
        standard
        material
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        elements
      }
      components {
        areaId
        areaName
        areaOrder
        id
        name
        slug
        order
        elements
      }
      interiors {
        id
        name
        type
        slug
        unit
        order
        elements
      }
      exteriors {
        id
        name
        type
        slug
        unit
        order
        elements
      }
    }
  }
`;
export const unlinkProductFromQuoteLine = /* GraphQL */ `
  mutation UnlinkProductFromQuoteLine($input: LinkProductToQuoteLineInput!) {
    unlinkProductFromQuoteLine(input: $input) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const linkProductToQuoteLine = /* GraphQL */ `
  mutation LinkProductToQuoteLine($input: LinkProductToQuoteLineInput!) {
    linkProductToQuoteLine(input: $input) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const deleteProductBom = /* GraphQL */ `
  mutation DeleteProductBom($input: DeleteProductBomInput!) {
    deleteProductBom(input: $input) {
      id
    }
  }
`;
export const deletePackageBom = /* GraphQL */ `
  mutation DeletePackageBom($input: DeleteProductBomInput!) {
    deletePackageBom(input: $input) {
      id
    }
  }
`;
export const exportInvoiceToSalesForce = /* GraphQL */ `
  mutation ExportInvoiceToSalesForce($input: ExportInvoiceToSalesForceInput) {
    exportInvoiceToSalesForce(input: $input) {
      id
      externalId
      projectId
      accountId
      contactId
      userId
      groupId
      order
      name
      invoiceNo
      issueDate
      billed
      billingDate
      description
      subtotal
      nonTaxableTotal
      taxableSubtotal
      taxTotal
      taxableTotal
      total
      accountName
      accountSite
      billingType
      accountAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      contactName
      contactFirstName
      contactLastName
      contactDepartment
      contactTitle
      groupName
      logoUrl
      groupBank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      taxRate
      url
      syncLogId
      syncedAt
      syncStatus
      totalPurchasingCost
      grossProfit
      grossProfitMargin
      recordingDate
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      invoiceLines {
        items {
          id
          externalId
          invoiceId
          vendorId
          vendorName
          vendorAccountCode
          vendorSite
          pricingId
          pricingName
          quoteLineId
          name
          shippingDate
          salesType
          order
          m3
          grossWeight
          quantity
          rt
          amount
          minimumAmount
          actualAmount
          unit
          unitPrice
          taxRate
          taxTotal
          subtotal
          total
          taxType
          sfTaxType
          synced
          description
          unitPurchasingCost
          totalPurchasingCost
          createdAt
          updatedAt
          invoice {
            id
            externalId
            projectId
            accountId
            contactId
            userId
            groupId
            order
            name
            invoiceNo
            issueDate
            billed
            billingDate
            description
            subtotal
            nonTaxableTotal
            taxableSubtotal
            taxTotal
            taxableTotal
            total
            accountName
            accountSite
            billingType
            accountAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            contactName
            contactFirstName
            contactLastName
            contactDepartment
            contactTitle
            groupName
            logoUrl
            groupBank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            phone
            fax
            taxRate
            url
            syncLogId
            syncedAt
            syncStatus
            totalPurchasingCost
            grossProfit
            grossProfitMargin
            recordingDate
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            invoiceLines {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const generateProductFromQuoteLine = /* GraphQL */ `
  mutation GenerateProductFromQuoteLine(
    $input: GenerateProductFromQuoteLineInput!
  ) {
    generateProductFromQuoteLine(input: $input) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      tenantId
      accountCode
      businessRegistrationNumber
      name
      abbreviation
      site
      billingAddress
      billingStreet
      billingCity
      billingState
      billingPostalCode
      billingCountry
      billingType
      fax
      phone
      customeCode
      cutoffDateText
      cutoffDateNumber
      paymentMonthText
      paymentMonthNumber
      paymentDateText
      paymentDateNumber
      invoiceUnit
      invoiceMethod
      active
      note
      accountId
      createdAt
      updatedAt
      contacts {
        items {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      tenantId
      accountCode
      businessRegistrationNumber
      name
      abbreviation
      site
      billingAddress
      billingStreet
      billingCity
      billingState
      billingPostalCode
      billingCountry
      billingType
      fax
      phone
      customeCode
      cutoffDateText
      cutoffDateNumber
      paymentMonthText
      paymentMonthNumber
      paymentDateText
      paymentDateNumber
      invoiceUnit
      invoiceMethod
      active
      note
      accountId
      createdAt
      updatedAt
      contacts {
        items {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      tenantId
      accountCode
      businessRegistrationNumber
      name
      abbreviation
      site
      billingAddress
      billingStreet
      billingCity
      billingState
      billingPostalCode
      billingCountry
      billingType
      fax
      phone
      customeCode
      cutoffDateText
      cutoffDateNumber
      paymentMonthText
      paymentMonthNumber
      paymentDateText
      paymentDateNumber
      invoiceUnit
      invoiceMethod
      active
      note
      accountId
      createdAt
      updatedAt
      contacts {
        items {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      accountId
      name
      abbreviation
      site
      billingAddress
      billingStreet
      billingCity
      billingState
      billingPostalCode
      billingCountry
      billingType
      phone
      fax
      customeCode
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      accountId
      name
      abbreviation
      site
      billingAddress
      billingStreet
      billingCity
      billingState
      billingPostalCode
      billingCountry
      billingType
      phone
      fax
      customeCode
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      accountId
      name
      abbreviation
      site
      billingAddress
      billingStreet
      billingCity
      billingState
      billingPostalCode
      billingCountry
      billingType
      phone
      fax
      customeCode
      createdAt
      updatedAt
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      accountId
      tenantId
      name
      firstName
      lastName
      suffix
      active
      email
      phone
      mobilePhone
      department
      title
      retired
      customeCode
      description
      contactId
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      accountId
      tenantId
      name
      firstName
      lastName
      suffix
      active
      email
      phone
      mobilePhone
      department
      title
      retired
      customeCode
      description
      contactId
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      accountId
      tenantId
      name
      firstName
      lastName
      suffix
      active
      email
      phone
      mobilePhone
      department
      title
      retired
      customeCode
      description
      contactId
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createUnit = /* GraphQL */ `
  mutation CreateUnit(
    $input: CreateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    createUnit(input: $input, condition: $condition) {
      id
      name
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUnit = /* GraphQL */ `
  mutation UpdateUnit(
    $input: UpdateUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    updateUnit(input: $input, condition: $condition) {
      id
      name
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUnit = /* GraphQL */ `
  mutation DeleteUnit(
    $input: DeleteUnitInput!
    $condition: ModelUnitConditionInput
  ) {
    deleteUnit(input: $input, condition: $condition) {
      id
      name
      tenantId
      createdAt
      updatedAt
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createHoliday = /* GraphQL */ `
  mutation CreateHoliday(
    $input: CreateHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    createHoliday(input: $input, condition: $condition) {
      id
      groupId
      name
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateHoliday = /* GraphQL */ `
  mutation UpdateHoliday(
    $input: UpdateHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    updateHoliday(input: $input, condition: $condition) {
      id
      groupId
      name
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteHoliday = /* GraphQL */ `
  mutation DeleteHoliday(
    $input: DeleteHolidayInput!
    $condition: ModelHolidayConditionInput
  ) {
    deleteHoliday(input: $input, condition: $condition) {
      id
      groupId
      name
      date
      createdAt
      updatedAt
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      externalId
      projectId
      accountId
      contactId
      userId
      groupId
      order
      name
      invoiceNo
      issueDate
      billed
      billingDate
      description
      subtotal
      nonTaxableTotal
      taxableSubtotal
      taxTotal
      taxableTotal
      total
      accountName
      accountSite
      billingType
      accountAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      contactName
      contactFirstName
      contactLastName
      contactDepartment
      contactTitle
      groupName
      logoUrl
      groupBank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      taxRate
      url
      syncLogId
      syncedAt
      syncStatus
      totalPurchasingCost
      grossProfit
      grossProfitMargin
      recordingDate
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      invoiceLines {
        items {
          id
          externalId
          invoiceId
          vendorId
          vendorName
          vendorAccountCode
          vendorSite
          pricingId
          pricingName
          quoteLineId
          name
          shippingDate
          salesType
          order
          m3
          grossWeight
          quantity
          rt
          amount
          minimumAmount
          actualAmount
          unit
          unitPrice
          taxRate
          taxTotal
          subtotal
          total
          taxType
          sfTaxType
          synced
          description
          unitPurchasingCost
          totalPurchasingCost
          createdAt
          updatedAt
          invoice {
            id
            externalId
            projectId
            accountId
            contactId
            userId
            groupId
            order
            name
            invoiceNo
            issueDate
            billed
            billingDate
            description
            subtotal
            nonTaxableTotal
            taxableSubtotal
            taxTotal
            taxableTotal
            total
            accountName
            accountSite
            billingType
            accountAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            contactName
            contactFirstName
            contactLastName
            contactDepartment
            contactTitle
            groupName
            logoUrl
            groupBank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            phone
            fax
            taxRate
            url
            syncLogId
            syncedAt
            syncStatus
            totalPurchasingCost
            grossProfit
            grossProfitMargin
            recordingDate
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            invoiceLines {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      externalId
      projectId
      accountId
      contactId
      userId
      groupId
      order
      name
      invoiceNo
      issueDate
      billed
      billingDate
      description
      subtotal
      nonTaxableTotal
      taxableSubtotal
      taxTotal
      taxableTotal
      total
      accountName
      accountSite
      billingType
      accountAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      contactName
      contactFirstName
      contactLastName
      contactDepartment
      contactTitle
      groupName
      logoUrl
      groupBank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      taxRate
      url
      syncLogId
      syncedAt
      syncStatus
      totalPurchasingCost
      grossProfit
      grossProfitMargin
      recordingDate
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      invoiceLines {
        items {
          id
          externalId
          invoiceId
          vendorId
          vendorName
          vendorAccountCode
          vendorSite
          pricingId
          pricingName
          quoteLineId
          name
          shippingDate
          salesType
          order
          m3
          grossWeight
          quantity
          rt
          amount
          minimumAmount
          actualAmount
          unit
          unitPrice
          taxRate
          taxTotal
          subtotal
          total
          taxType
          sfTaxType
          synced
          description
          unitPurchasingCost
          totalPurchasingCost
          createdAt
          updatedAt
          invoice {
            id
            externalId
            projectId
            accountId
            contactId
            userId
            groupId
            order
            name
            invoiceNo
            issueDate
            billed
            billingDate
            description
            subtotal
            nonTaxableTotal
            taxableSubtotal
            taxTotal
            taxableTotal
            total
            accountName
            accountSite
            billingType
            accountAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            contactName
            contactFirstName
            contactLastName
            contactDepartment
            contactTitle
            groupName
            logoUrl
            groupBank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            phone
            fax
            taxRate
            url
            syncLogId
            syncedAt
            syncStatus
            totalPurchasingCost
            grossProfit
            grossProfitMargin
            recordingDate
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            invoiceLines {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      externalId
      projectId
      accountId
      contactId
      userId
      groupId
      order
      name
      invoiceNo
      issueDate
      billed
      billingDate
      description
      subtotal
      nonTaxableTotal
      taxableSubtotal
      taxTotal
      taxableTotal
      total
      accountName
      accountSite
      billingType
      accountAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      contactName
      contactFirstName
      contactLastName
      contactDepartment
      contactTitle
      groupName
      logoUrl
      groupBank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      taxRate
      url
      syncLogId
      syncedAt
      syncStatus
      totalPurchasingCost
      grossProfit
      grossProfitMargin
      recordingDate
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      invoiceLines {
        items {
          id
          externalId
          invoiceId
          vendorId
          vendorName
          vendorAccountCode
          vendorSite
          pricingId
          pricingName
          quoteLineId
          name
          shippingDate
          salesType
          order
          m3
          grossWeight
          quantity
          rt
          amount
          minimumAmount
          actualAmount
          unit
          unitPrice
          taxRate
          taxTotal
          subtotal
          total
          taxType
          sfTaxType
          synced
          description
          unitPurchasingCost
          totalPurchasingCost
          createdAt
          updatedAt
          invoice {
            id
            externalId
            projectId
            accountId
            contactId
            userId
            groupId
            order
            name
            invoiceNo
            issueDate
            billed
            billingDate
            description
            subtotal
            nonTaxableTotal
            taxableSubtotal
            taxTotal
            taxableTotal
            total
            accountName
            accountSite
            billingType
            accountAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            contactName
            contactFirstName
            contactLastName
            contactDepartment
            contactTitle
            groupName
            logoUrl
            groupBank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            phone
            fax
            taxRate
            url
            syncLogId
            syncedAt
            syncStatus
            totalPurchasingCost
            grossProfit
            grossProfitMargin
            recordingDate
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            invoiceLines {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createInvoiceLine = /* GraphQL */ `
  mutation CreateInvoiceLine(
    $input: CreateInvoiceLineInput!
    $condition: ModelInvoiceLineConditionInput
  ) {
    createInvoiceLine(input: $input, condition: $condition) {
      id
      externalId
      invoiceId
      vendorId
      vendorName
      vendorAccountCode
      vendorSite
      pricingId
      pricingName
      quoteLineId
      name
      shippingDate
      salesType
      order
      m3
      grossWeight
      quantity
      rt
      amount
      minimumAmount
      actualAmount
      unit
      unitPrice
      taxRate
      taxTotal
      subtotal
      total
      taxType
      sfTaxType
      synced
      description
      unitPurchasingCost
      totalPurchasingCost
      createdAt
      updatedAt
      invoice {
        id
        externalId
        projectId
        accountId
        contactId
        userId
        groupId
        order
        name
        invoiceNo
        issueDate
        billed
        billingDate
        description
        subtotal
        nonTaxableTotal
        taxableSubtotal
        taxTotal
        taxableTotal
        total
        accountName
        accountSite
        billingType
        accountAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        contactName
        contactFirstName
        contactLastName
        contactDepartment
        contactTitle
        groupName
        logoUrl
        groupBank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        phone
        fax
        taxRate
        url
        syncLogId
        syncedAt
        syncStatus
        totalPurchasingCost
        grossProfit
        grossProfitMargin
        recordingDate
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        invoiceLines {
          items {
            id
            externalId
            invoiceId
            vendorId
            vendorName
            vendorAccountCode
            vendorSite
            pricingId
            pricingName
            quoteLineId
            name
            shippingDate
            salesType
            order
            m3
            grossWeight
            quantity
            rt
            amount
            minimumAmount
            actualAmount
            unit
            unitPrice
            taxRate
            taxTotal
            subtotal
            total
            taxType
            sfTaxType
            synced
            description
            unitPurchasingCost
            totalPurchasingCost
            createdAt
            updatedAt
            invoice {
              id
              externalId
              projectId
              accountId
              contactId
              userId
              groupId
              order
              name
              invoiceNo
              issueDate
              billed
              billingDate
              description
              subtotal
              nonTaxableTotal
              taxableSubtotal
              taxTotal
              taxableTotal
              total
              accountName
              accountSite
              billingType
              contactName
              contactFirstName
              contactLastName
              contactDepartment
              contactTitle
              groupName
              logoUrl
              phone
              fax
              taxRate
              url
              syncLogId
              syncedAt
              syncStatus
              totalPurchasingCost
              grossProfit
              grossProfitMargin
              recordingDate
              createdAt
              updatedAt
            }
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateInvoiceLine = /* GraphQL */ `
  mutation UpdateInvoiceLine(
    $input: UpdateInvoiceLineInput!
    $condition: ModelInvoiceLineConditionInput
  ) {
    updateInvoiceLine(input: $input, condition: $condition) {
      id
      externalId
      invoiceId
      vendorId
      vendorName
      vendorAccountCode
      vendorSite
      pricingId
      pricingName
      quoteLineId
      name
      shippingDate
      salesType
      order
      m3
      grossWeight
      quantity
      rt
      amount
      minimumAmount
      actualAmount
      unit
      unitPrice
      taxRate
      taxTotal
      subtotal
      total
      taxType
      sfTaxType
      synced
      description
      unitPurchasingCost
      totalPurchasingCost
      createdAt
      updatedAt
      invoice {
        id
        externalId
        projectId
        accountId
        contactId
        userId
        groupId
        order
        name
        invoiceNo
        issueDate
        billed
        billingDate
        description
        subtotal
        nonTaxableTotal
        taxableSubtotal
        taxTotal
        taxableTotal
        total
        accountName
        accountSite
        billingType
        accountAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        contactName
        contactFirstName
        contactLastName
        contactDepartment
        contactTitle
        groupName
        logoUrl
        groupBank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        phone
        fax
        taxRate
        url
        syncLogId
        syncedAt
        syncStatus
        totalPurchasingCost
        grossProfit
        grossProfitMargin
        recordingDate
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        invoiceLines {
          items {
            id
            externalId
            invoiceId
            vendorId
            vendorName
            vendorAccountCode
            vendorSite
            pricingId
            pricingName
            quoteLineId
            name
            shippingDate
            salesType
            order
            m3
            grossWeight
            quantity
            rt
            amount
            minimumAmount
            actualAmount
            unit
            unitPrice
            taxRate
            taxTotal
            subtotal
            total
            taxType
            sfTaxType
            synced
            description
            unitPurchasingCost
            totalPurchasingCost
            createdAt
            updatedAt
            invoice {
              id
              externalId
              projectId
              accountId
              contactId
              userId
              groupId
              order
              name
              invoiceNo
              issueDate
              billed
              billingDate
              description
              subtotal
              nonTaxableTotal
              taxableSubtotal
              taxTotal
              taxableTotal
              total
              accountName
              accountSite
              billingType
              contactName
              contactFirstName
              contactLastName
              contactDepartment
              contactTitle
              groupName
              logoUrl
              phone
              fax
              taxRate
              url
              syncLogId
              syncedAt
              syncStatus
              totalPurchasingCost
              grossProfit
              grossProfitMargin
              recordingDate
              createdAt
              updatedAt
            }
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteInvoiceLine = /* GraphQL */ `
  mutation DeleteInvoiceLine(
    $input: DeleteInvoiceLineInput!
    $condition: ModelInvoiceLineConditionInput
  ) {
    deleteInvoiceLine(input: $input, condition: $condition) {
      id
      externalId
      invoiceId
      vendorId
      vendorName
      vendorAccountCode
      vendorSite
      pricingId
      pricingName
      quoteLineId
      name
      shippingDate
      salesType
      order
      m3
      grossWeight
      quantity
      rt
      amount
      minimumAmount
      actualAmount
      unit
      unitPrice
      taxRate
      taxTotal
      subtotal
      total
      taxType
      sfTaxType
      synced
      description
      unitPurchasingCost
      totalPurchasingCost
      createdAt
      updatedAt
      invoice {
        id
        externalId
        projectId
        accountId
        contactId
        userId
        groupId
        order
        name
        invoiceNo
        issueDate
        billed
        billingDate
        description
        subtotal
        nonTaxableTotal
        taxableSubtotal
        taxTotal
        taxableTotal
        total
        accountName
        accountSite
        billingType
        accountAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        contactName
        contactFirstName
        contactLastName
        contactDepartment
        contactTitle
        groupName
        logoUrl
        groupBank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        phone
        fax
        taxRate
        url
        syncLogId
        syncedAt
        syncStatus
        totalPurchasingCost
        grossProfit
        grossProfitMargin
        recordingDate
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        invoiceLines {
          items {
            id
            externalId
            invoiceId
            vendorId
            vendorName
            vendorAccountCode
            vendorSite
            pricingId
            pricingName
            quoteLineId
            name
            shippingDate
            salesType
            order
            m3
            grossWeight
            quantity
            rt
            amount
            minimumAmount
            actualAmount
            unit
            unitPrice
            taxRate
            taxTotal
            subtotal
            total
            taxType
            sfTaxType
            synced
            description
            unitPurchasingCost
            totalPurchasingCost
            createdAt
            updatedAt
            invoice {
              id
              externalId
              projectId
              accountId
              contactId
              userId
              groupId
              order
              name
              invoiceNo
              issueDate
              billed
              billingDate
              description
              subtotal
              nonTaxableTotal
              taxableSubtotal
              taxTotal
              taxableTotal
              total
              accountName
              accountSite
              billingType
              contactName
              contactFirstName
              contactLastName
              contactDepartment
              contactTitle
              groupName
              logoUrl
              phone
              fax
              taxRate
              url
              syncLogId
              syncedAt
              syncStatus
              totalPurchasingCost
              grossProfit
              grossProfitMargin
              recordingDate
              createdAt
              updatedAt
            }
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createMetaTable = /* GraphQL */ `
  mutation CreateMetaTable(
    $input: CreateMetaTableInput!
    $condition: ModelMetaTableConditionInput
  ) {
    createMetaTable(input: $input, condition: $condition) {
      id
      name
      label
      fields {
        name
        label
        type
        required
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMetaTable = /* GraphQL */ `
  mutation UpdateMetaTable(
    $input: UpdateMetaTableInput!
    $condition: ModelMetaTableConditionInput
  ) {
    updateMetaTable(input: $input, condition: $condition) {
      id
      name
      label
      fields {
        name
        label
        type
        required
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMetaTable = /* GraphQL */ `
  mutation DeleteMetaTable(
    $input: DeleteMetaTableInput!
    $condition: ModelMetaTableConditionInput
  ) {
    deleteMetaTable(input: $input, condition: $condition) {
      id
      name
      label
      fields {
        name
        label
        type
        required
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPackageType = /* GraphQL */ `
  mutation CreatePackageType(
    $input: CreatePackageTypeInput!
    $condition: ModelPackageTypeConditionInput
  ) {
    createPackageType(input: $input, condition: $condition) {
      id
      groupId
      pricingId
      name
      description
      publish
      quantity
      material
      stamp
      packageMethod
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      panelM2
      woodM3
      tareWeight
      grossWeight
      note
      standard
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      active
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      areas {
        items {
          id
          packageTypeId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              baseAreaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          packageTypeId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updatePackageType = /* GraphQL */ `
  mutation UpdatePackageType(
    $input: UpdatePackageTypeInput!
    $condition: ModelPackageTypeConditionInput
  ) {
    updatePackageType(input: $input, condition: $condition) {
      id
      groupId
      pricingId
      name
      description
      publish
      quantity
      material
      stamp
      packageMethod
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      panelM2
      woodM3
      tareWeight
      grossWeight
      note
      standard
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      active
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      areas {
        items {
          id
          packageTypeId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              baseAreaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          packageTypeId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deletePackageType = /* GraphQL */ `
  mutation DeletePackageType(
    $input: DeletePackageTypeInput!
    $condition: ModelPackageTypeConditionInput
  ) {
    deletePackageType(input: $input, condition: $condition) {
      id
      groupId
      pricingId
      name
      description
      publish
      quantity
      material
      stamp
      packageMethod
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      panelM2
      woodM3
      tareWeight
      grossWeight
      note
      standard
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      active
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      areas {
        items {
          id
          packageTypeId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              baseAreaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          packageTypeId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createBaseArea = /* GraphQL */ `
  mutation CreateBaseArea(
    $input: CreateBaseAreaInput!
    $condition: ModelBaseAreaConditionInput
  ) {
    createBaseArea(input: $input, condition: $condition) {
      id
      packageTypeId
      name
      areaType
      order
      description
      createdAt
      updatedAt
      components {
        items {
          id
          baseAreaId
          name
          slug
          order
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateBaseArea = /* GraphQL */ `
  mutation UpdateBaseArea(
    $input: UpdateBaseAreaInput!
    $condition: ModelBaseAreaConditionInput
  ) {
    updateBaseArea(input: $input, condition: $condition) {
      id
      packageTypeId
      name
      areaType
      order
      description
      createdAt
      updatedAt
      components {
        items {
          id
          baseAreaId
          name
          slug
          order
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteBaseArea = /* GraphQL */ `
  mutation DeleteBaseArea(
    $input: DeleteBaseAreaInput!
    $condition: ModelBaseAreaConditionInput
  ) {
    deleteBaseArea(input: $input, condition: $condition) {
      id
      packageTypeId
      name
      areaType
      order
      description
      createdAt
      updatedAt
      components {
        items {
          id
          baseAreaId
          name
          slug
          order
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBaseComponent = /* GraphQL */ `
  mutation CreateBaseComponent(
    $input: CreateBaseComponentInput!
    $condition: ModelBaseComponentConditionInput
  ) {
    createBaseComponent(input: $input, condition: $condition) {
      id
      baseAreaId
      name
      slug
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBaseComponent = /* GraphQL */ `
  mutation UpdateBaseComponent(
    $input: UpdateBaseComponentInput!
    $condition: ModelBaseComponentConditionInput
  ) {
    updateBaseComponent(input: $input, condition: $condition) {
      id
      baseAreaId
      name
      slug
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBaseComponent = /* GraphQL */ `
  mutation DeleteBaseComponent(
    $input: DeleteBaseComponentInput!
    $condition: ModelBaseComponentConditionInput
  ) {
    deleteBaseComponent(input: $input, condition: $condition) {
      id
      baseAreaId
      name
      slug
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBaseDecorationComponent = /* GraphQL */ `
  mutation CreateBaseDecorationComponent(
    $input: CreateBaseDecorationComponentInput!
    $condition: ModelBaseDecorationComponentConditionInput
  ) {
    createBaseDecorationComponent(input: $input, condition: $condition) {
      id
      packageTypeId
      type
      name
      slug
      order
      unit
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBaseDecorationComponent = /* GraphQL */ `
  mutation UpdateBaseDecorationComponent(
    $input: UpdateBaseDecorationComponentInput!
    $condition: ModelBaseDecorationComponentConditionInput
  ) {
    updateBaseDecorationComponent(input: $input, condition: $condition) {
      id
      packageTypeId
      type
      name
      slug
      order
      unit
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBaseDecorationComponent = /* GraphQL */ `
  mutation DeleteBaseDecorationComponent(
    $input: DeleteBaseDecorationComponentInput!
    $condition: ModelBaseDecorationComponentConditionInput
  ) {
    deleteBaseDecorationComponent(input: $input, condition: $condition) {
      id
      packageTypeId
      type
      name
      slug
      order
      unit
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPriceType = /* GraphQL */ `
  mutation CreatePriceType(
    $input: CreatePriceTypeInput!
    $condition: ModelPriceTypeConditionInput
  ) {
    createPriceType(input: $input, condition: $condition) {
      id
      groupId
      name
      description
      showInQuoteSummary
      active
      createdAt
      updatedAt
      pricings {
        items {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updatePriceType = /* GraphQL */ `
  mutation UpdatePriceType(
    $input: UpdatePriceTypeInput!
    $condition: ModelPriceTypeConditionInput
  ) {
    updatePriceType(input: $input, condition: $condition) {
      id
      groupId
      name
      description
      showInQuoteSummary
      active
      createdAt
      updatedAt
      pricings {
        items {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deletePriceType = /* GraphQL */ `
  mutation DeletePriceType(
    $input: DeletePriceTypeInput!
    $condition: ModelPriceTypeConditionInput
  ) {
    deletePriceType(input: $input, condition: $condition) {
      id
      groupId
      name
      description
      showInQuoteSummary
      active
      createdAt
      updatedAt
      pricings {
        items {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createPricing = /* GraphQL */ `
  mutation CreatePricing(
    $input: CreatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    createPricing(input: $input, condition: $condition) {
      id
      groupId
      priceTypeId
      packageTypeId
      isDefault
      name
      packageTypeName
      unitPrice
      unit
      autoCalculate
      minimumAmount
      taxType
      sizeDependent
      pricesBySize {
        minSize
        maxSize
        unitPrice
      }
      accountSpecific
      accounts {
        key
        label
      }
      active
      description
      createdAt
      updatedAt
      priceType {
        id
        groupId
        name
        description
        showInQuoteSummary
        active
        createdAt
        updatedAt
        pricings {
          items {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePricing = /* GraphQL */ `
  mutation UpdatePricing(
    $input: UpdatePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    updatePricing(input: $input, condition: $condition) {
      id
      groupId
      priceTypeId
      packageTypeId
      isDefault
      name
      packageTypeName
      unitPrice
      unit
      autoCalculate
      minimumAmount
      taxType
      sizeDependent
      pricesBySize {
        minSize
        maxSize
        unitPrice
      }
      accountSpecific
      accounts {
        key
        label
      }
      active
      description
      createdAt
      updatedAt
      priceType {
        id
        groupId
        name
        description
        showInQuoteSummary
        active
        createdAt
        updatedAt
        pricings {
          items {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deletePricing = /* GraphQL */ `
  mutation DeletePricing(
    $input: DeletePricingInput!
    $condition: ModelPricingConditionInput
  ) {
    deletePricing(input: $input, condition: $condition) {
      id
      groupId
      priceTypeId
      packageTypeId
      isDefault
      name
      packageTypeName
      unitPrice
      unit
      autoCalculate
      minimumAmount
      taxType
      sizeDependent
      pricesBySize {
        minSize
        maxSize
        unitPrice
      }
      accountSpecific
      accounts {
        key
        label
      }
      active
      description
      createdAt
      updatedAt
      priceType {
        id
        groupId
        name
        description
        showInQuoteSummary
        active
        createdAt
        updatedAt
        pricings {
          items {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const createArea = /* GraphQL */ `
  mutation CreateArea(
    $input: CreateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    createArea(input: $input, condition: $condition) {
      id
      productId
      name
      areaType
      order
      description
      createdAt
      updatedAt
      components {
        items {
          id
          areaId
          name
          slug
          order
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateArea = /* GraphQL */ `
  mutation UpdateArea(
    $input: UpdateAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    updateArea(input: $input, condition: $condition) {
      id
      productId
      name
      areaType
      order
      description
      createdAt
      updatedAt
      components {
        items {
          id
          areaId
          name
          slug
          order
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteArea = /* GraphQL */ `
  mutation DeleteArea(
    $input: DeleteAreaInput!
    $condition: ModelAreaConditionInput
  ) {
    deleteArea(input: $input, condition: $condition) {
      id
      productId
      name
      areaType
      order
      description
      createdAt
      updatedAt
      components {
        items {
          id
          areaId
          name
          slug
          order
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createComponent = /* GraphQL */ `
  mutation CreateComponent(
    $input: CreateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    createComponent(input: $input, condition: $condition) {
      id
      areaId
      name
      slug
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateComponent = /* GraphQL */ `
  mutation UpdateComponent(
    $input: UpdateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    updateComponent(input: $input, condition: $condition) {
      id
      areaId
      name
      slug
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteComponent = /* GraphQL */ `
  mutation DeleteComponent(
    $input: DeleteComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    deleteComponent(input: $input, condition: $condition) {
      id
      areaId
      name
      slug
      order
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDecorationComponent = /* GraphQL */ `
  mutation CreateDecorationComponent(
    $input: CreateDecorationComponentInput!
    $condition: ModelDecorationComponentConditionInput
  ) {
    createDecorationComponent(input: $input, condition: $condition) {
      id
      productId
      type
      name
      slug
      order
      unit
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDecorationComponent = /* GraphQL */ `
  mutation UpdateDecorationComponent(
    $input: UpdateDecorationComponentInput!
    $condition: ModelDecorationComponentConditionInput
  ) {
    updateDecorationComponent(input: $input, condition: $condition) {
      id
      productId
      type
      name
      slug
      order
      unit
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDecorationComponent = /* GraphQL */ `
  mutation DeleteDecorationComponent(
    $input: DeleteDecorationComponentInput!
    $condition: ModelDecorationComponentConditionInput
  ) {
    deleteDecorationComponent(input: $input, condition: $condition) {
      id
      productId
      type
      name
      slug
      order
      unit
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSummary = /* GraphQL */ `
  mutation CreateSummary(
    $input: CreateSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    createSummary(input: $input, condition: $condition) {
      id
      projectId
      pricingId
      pricingName
      productId
      quoteLineId
      invoiceLineId
      isBilled
      name
      order
      quantity
      tareWeight
      productLength
      productWidth
      productHeight
      outerLength
      outerWidth
      outerHeight
      unitM3
      unitNW
      unitGrossWeight
      NW
      grossWeight
      m3
      unitPrice
      unit
      autoCalculate
      taxType
      overwritten
      createdAt
      updatedAt
    }
  }
`;
export const updateSummary = /* GraphQL */ `
  mutation UpdateSummary(
    $input: UpdateSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    updateSummary(input: $input, condition: $condition) {
      id
      projectId
      pricingId
      pricingName
      productId
      quoteLineId
      invoiceLineId
      isBilled
      name
      order
      quantity
      tareWeight
      productLength
      productWidth
      productHeight
      outerLength
      outerWidth
      outerHeight
      unitM3
      unitNW
      unitGrossWeight
      NW
      grossWeight
      m3
      unitPrice
      unit
      autoCalculate
      taxType
      overwritten
      createdAt
      updatedAt
    }
  }
`;
export const deleteSummary = /* GraphQL */ `
  mutation DeleteSummary(
    $input: DeleteSummaryInput!
    $condition: ModelSummaryConditionInput
  ) {
    deleteSummary(input: $input, condition: $condition) {
      id
      projectId
      pricingId
      pricingName
      productId
      quoteLineId
      invoiceLineId
      isBilled
      name
      order
      quantity
      tareWeight
      productLength
      productWidth
      productHeight
      outerLength
      outerWidth
      outerHeight
      unitM3
      unitNW
      unitGrossWeight
      NW
      grossWeight
      m3
      unitPrice
      unit
      autoCalculate
      taxType
      overwritten
      createdAt
      updatedAt
    }
  }
`;
export const createQuoteProduct = /* GraphQL */ `
  mutation CreateQuoteProduct(
    $input: CreateQuoteProductInput!
    $condition: ModelQuoteProductConditionInput
  ) {
    createQuoteProduct(input: $input, condition: $condition) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const updateQuoteProduct = /* GraphQL */ `
  mutation UpdateQuoteProduct(
    $input: UpdateQuoteProductInput!
    $condition: ModelQuoteProductConditionInput
  ) {
    updateQuoteProduct(input: $input, condition: $condition) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const deleteQuoteProduct = /* GraphQL */ `
  mutation DeleteQuoteProduct(
    $input: DeleteQuoteProductInput!
    $condition: ModelQuoteProductConditionInput
  ) {
    deleteQuoteProduct(input: $input, condition: $condition) {
      id
      projectId
      packageTypeId
      scheduleId
      userId
      pricingId
      overwriteUnitPrice
      overwriteUnit
      packageTypeName
      quoteLineId
      quoteLineName
      unit
      name
      order
      productNumber
      quantity
      material
      panelM2
      woodM3
      tareWeight
      grossWeight
      m3
      stamp
      destination
      packagingStart
      packagingEnd
      packageMethod
      description
      readyToPack
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      cases {
        order
        name
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        phaseId
        isPacked
        netWeight
        grossWeight
        outerLength
        outerWidth
        outerHeight
        m3
        thumbnail
        managementNumber
        isBilled
        invoiceLineId
      }
      note
      woodCost
      lvlCost
      panelCost
      interiorCost
      exteriorCost
      m3Cost
      totalCost
      elements {
        type
        name
        slug
        value
        expr
        description
        overwriteValue
        cutting
      }
      createdAt
      updatedAt
      packageType {
        id
        groupId
        pricingId
        name
        description
        publish
        quantity
        material
        stamp
        packageMethod
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        panelM2
        woodM3
        tareWeight
        grossWeight
        note
        standard
        woodCost
        lvlCost
        panelCost
        interiorCost
        exteriorCost
        m3Cost
        totalCost
        active
        order
        elements {
          type
          name
          slug
          value
          expr
          description
          overwriteValue
          cutting
        }
        createdAt
        updatedAt
        areas {
          items {
            id
            packageTypeId
            name
            areaType
            order
            description
            createdAt
            updatedAt
            components {
              nextToken
            }
          }
          nextToken
        }
        decorations {
          items {
            id
            packageTypeId
            type
            name
            slug
            order
            unit
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        pricing {
          id
          groupId
          priceTypeId
          packageTypeId
          isDefault
          name
          packageTypeName
          unitPrice
          unit
          autoCalculate
          minimumAmount
          taxType
          sizeDependent
          pricesBySize {
            minSize
            maxSize
            unitPrice
          }
          accountSpecific
          accounts {
            key
            label
          }
          active
          description
          createdAt
          updatedAt
          priceType {
            id
            groupId
            name
            description
            showInQuoteSummary
            active
            createdAt
            updatedAt
            pricings {
              nextToken
            }
          }
        }
      }
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      areas {
        items {
          id
          productId
          name
          areaType
          order
          description
          createdAt
          updatedAt
          components {
            items {
              id
              areaId
              name
              slug
              order
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      decorations {
        items {
          id
          productId
          type
          name
          slug
          order
          unit
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule {
        id
        projectId
        name
        m3
        case
        shipType
        stockingDate
        packagingDate
        shippingDate
        cutDate
        numImgs
        shipSubFolders {
          id
          name
          numImgs
        }
        stockNumImgs
        stockSubFolders {
          id
          name
          numImgs
        }
        isShipped
        note
        createdAt
        updatedAt
        products {
          items {
            id
            projectId
            packageTypeId
            scheduleId
            userId
            pricingId
            overwriteUnitPrice
            overwriteUnit
            packageTypeName
            quoteLineId
            quoteLineName
            unit
            name
            order
            productNumber
            quantity
            material
            panelM2
            woodM3
            tareWeight
            grossWeight
            m3
            stamp
            destination
            packagingStart
            packagingEnd
            packageMethod
            description
            readyToPack
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            cases {
              order
              name
              phaseId
              isPacked
              netWeight
              grossWeight
              outerLength
              outerWidth
              outerHeight
              m3
              thumbnail
              managementNumber
              isBilled
              invoiceLineId
            }
            note
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            packageType {
              id
              groupId
              pricingId
              name
              description
              publish
              quantity
              material
              stamp
              packageMethod
              panelM2
              woodM3
              tareWeight
              grossWeight
              note
              standard
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              active
              order
              createdAt
              updatedAt
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            schedule {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        project {
          id
          groupId
          quoteId
          userId
          managerId
          managerName
          quoterId
          quoterName
          operatorId
          operatorName
          accountId
          accountName
          contactId
          contactName
          name
          status
          inquiryDate
          quotationDate
          destinationName
          destination {
            lat
            lng
          }
          confidence
          description
          alert
          cancelled
          cancelReason
          caseMarkNumImgs
          stockNumImgs
          reference
          photoPhases {
            id
            name
            requiresPhoto
            numImgs
          }
          type
          archived
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          contact {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          schedules {
            items {
              id
              projectId
              name
              m3
              case
              shipType
              stockingDate
              packagingDate
              shippingDate
              cutDate
              numImgs
              stockNumImgs
              isShipped
              note
              createdAt
              updatedAt
            }
            nextToken
          }
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      groupId
      quoteId
      userId
      managerId
      managerName
      quoterId
      quoterName
      operatorId
      operatorName
      accountId
      accountName
      contactId
      contactName
      name
      status
      inquiryDate
      quotationDate
      destinationName
      destination {
        lat
        lng
      }
      confidence
      description
      alert
      cancelled
      cancelReason
      caseMarkNumImgs
      stockNumImgs
      reference
      photoPhases {
        id
        name
        requiresPhoto
        numImgs
      }
      type
      archived
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      schedules {
        items {
          id
          projectId
          name
          m3
          case
          shipType
          stockingDate
          packagingDate
          shippingDate
          cutDate
          numImgs
          shipSubFolders {
            id
            name
            numImgs
          }
          stockNumImgs
          stockSubFolders {
            id
            name
            numImgs
          }
          isShipped
          note
          createdAt
          updatedAt
          products {
            items {
              id
              projectId
              packageTypeId
              scheduleId
              userId
              pricingId
              overwriteUnitPrice
              overwriteUnit
              packageTypeName
              quoteLineId
              quoteLineName
              unit
              name
              order
              productNumber
              quantity
              material
              panelM2
              woodM3
              tareWeight
              grossWeight
              m3
              stamp
              destination
              packagingStart
              packagingEnd
              packageMethod
              description
              readyToPack
              note
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              createdAt
              updatedAt
            }
            nextToken
          }
          project {
            id
            groupId
            quoteId
            userId
            managerId
            managerName
            quoterId
            quoterName
            operatorId
            operatorName
            accountId
            accountName
            contactId
            contactName
            name
            status
            inquiryDate
            quotationDate
            destinationName
            destination {
              lat
              lng
            }
            confidence
            description
            alert
            cancelled
            cancelReason
            caseMarkNumImgs
            stockNumImgs
            reference
            photoPhases {
              id
              name
              requiresPhoto
              numImgs
            }
            type
            archived
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            schedules {
              nextToken
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      groupId
      quoteId
      userId
      managerId
      managerName
      quoterId
      quoterName
      operatorId
      operatorName
      accountId
      accountName
      contactId
      contactName
      name
      status
      inquiryDate
      quotationDate
      destinationName
      destination {
        lat
        lng
      }
      confidence
      description
      alert
      cancelled
      cancelReason
      caseMarkNumImgs
      stockNumImgs
      reference
      photoPhases {
        id
        name
        requiresPhoto
        numImgs
      }
      type
      archived
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      schedules {
        items {
          id
          projectId
          name
          m3
          case
          shipType
          stockingDate
          packagingDate
          shippingDate
          cutDate
          numImgs
          shipSubFolders {
            id
            name
            numImgs
          }
          stockNumImgs
          stockSubFolders {
            id
            name
            numImgs
          }
          isShipped
          note
          createdAt
          updatedAt
          products {
            items {
              id
              projectId
              packageTypeId
              scheduleId
              userId
              pricingId
              overwriteUnitPrice
              overwriteUnit
              packageTypeName
              quoteLineId
              quoteLineName
              unit
              name
              order
              productNumber
              quantity
              material
              panelM2
              woodM3
              tareWeight
              grossWeight
              m3
              stamp
              destination
              packagingStart
              packagingEnd
              packageMethod
              description
              readyToPack
              note
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              createdAt
              updatedAt
            }
            nextToken
          }
          project {
            id
            groupId
            quoteId
            userId
            managerId
            managerName
            quoterId
            quoterName
            operatorId
            operatorName
            accountId
            accountName
            contactId
            contactName
            name
            status
            inquiryDate
            quotationDate
            destinationName
            destination {
              lat
              lng
            }
            confidence
            description
            alert
            cancelled
            cancelReason
            caseMarkNumImgs
            stockNumImgs
            reference
            photoPhases {
              id
              name
              requiresPhoto
              numImgs
            }
            type
            archived
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            schedules {
              nextToken
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      groupId
      quoteId
      userId
      managerId
      managerName
      quoterId
      quoterName
      operatorId
      operatorName
      accountId
      accountName
      contactId
      contactName
      name
      status
      inquiryDate
      quotationDate
      destinationName
      destination {
        lat
        lng
      }
      confidence
      description
      alert
      cancelled
      cancelReason
      caseMarkNumImgs
      stockNumImgs
      reference
      photoPhases {
        id
        name
        requiresPhoto
        numImgs
      }
      type
      archived
      createdAt
      updatedAt
      account {
        id
        tenantId
        accountCode
        businessRegistrationNumber
        name
        abbreviation
        site
        billingAddress
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
        billingType
        fax
        phone
        customeCode
        cutoffDateText
        cutoffDateNumber
        paymentMonthText
        paymentMonthNumber
        paymentDateText
        paymentDateNumber
        invoiceUnit
        invoiceMethod
        active
        note
        accountId
        createdAt
        updatedAt
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      contact {
        id
        accountId
        tenantId
        name
        firstName
        lastName
        suffix
        active
        email
        phone
        mobilePhone
        department
        title
        retired
        customeCode
        description
        contactId
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
      schedules {
        items {
          id
          projectId
          name
          m3
          case
          shipType
          stockingDate
          packagingDate
          shippingDate
          cutDate
          numImgs
          shipSubFolders {
            id
            name
            numImgs
          }
          stockNumImgs
          stockSubFolders {
            id
            name
            numImgs
          }
          isShipped
          note
          createdAt
          updatedAt
          products {
            items {
              id
              projectId
              packageTypeId
              scheduleId
              userId
              pricingId
              overwriteUnitPrice
              overwriteUnit
              packageTypeName
              quoteLineId
              quoteLineName
              unit
              name
              order
              productNumber
              quantity
              material
              panelM2
              woodM3
              tareWeight
              grossWeight
              m3
              stamp
              destination
              packagingStart
              packagingEnd
              packageMethod
              description
              readyToPack
              note
              woodCost
              lvlCost
              panelCost
              interiorCost
              exteriorCost
              m3Cost
              totalCost
              createdAt
              updatedAt
            }
            nextToken
          }
          project {
            id
            groupId
            quoteId
            userId
            managerId
            managerName
            quoterId
            quoterName
            operatorId
            operatorName
            accountId
            accountName
            contactId
            contactName
            name
            status
            inquiryDate
            quotationDate
            destinationName
            destination {
              lat
              lng
            }
            confidence
            description
            alert
            cancelled
            cancelReason
            caseMarkNumImgs
            stockNumImgs
            reference
            photoPhases {
              id
              name
              requiresPhoto
              numImgs
            }
            type
            archived
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            contact {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            schedules {
              nextToken
            }
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      projectId
      name
      m3
      case
      shipType
      stockingDate
      packagingDate
      shippingDate
      cutDate
      numImgs
      shipSubFolders {
        id
        name
        numImgs
      }
      stockNumImgs
      stockSubFolders {
        id
        name
        numImgs
      }
      isShipped
      note
      createdAt
      updatedAt
      products {
        items {
          id
          projectId
          packageTypeId
          scheduleId
          userId
          pricingId
          overwriteUnitPrice
          overwriteUnit
          packageTypeName
          quoteLineId
          quoteLineName
          unit
          name
          order
          productNumber
          quantity
          material
          panelM2
          woodM3
          tareWeight
          grossWeight
          m3
          stamp
          destination
          packagingStart
          packagingEnd
          packageMethod
          description
          readyToPack
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          cases {
            order
            name
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            phaseId
            isPacked
            netWeight
            grossWeight
            outerLength
            outerWidth
            outerHeight
            m3
            thumbnail
            managementNumber
            isBilled
            invoiceLineId
          }
          note
          woodCost
          lvlCost
          panelCost
          interiorCost
          exteriorCost
          m3Cost
          totalCost
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
          packageType {
            id
            groupId
            pricingId
            name
            description
            publish
            quantity
            material
            stamp
            packageMethod
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            panelM2
            woodM3
            tareWeight
            grossWeight
            note
            standard
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            active
            order
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
          }
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
          areas {
            items {
              id
              productId
              name
              areaType
              order
              description
              createdAt
              updatedAt
            }
            nextToken
          }
          decorations {
            items {
              id
              productId
              type
              name
              slug
              order
              unit
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      projectId
      name
      m3
      case
      shipType
      stockingDate
      packagingDate
      shippingDate
      cutDate
      numImgs
      shipSubFolders {
        id
        name
        numImgs
      }
      stockNumImgs
      stockSubFolders {
        id
        name
        numImgs
      }
      isShipped
      note
      createdAt
      updatedAt
      products {
        items {
          id
          projectId
          packageTypeId
          scheduleId
          userId
          pricingId
          overwriteUnitPrice
          overwriteUnit
          packageTypeName
          quoteLineId
          quoteLineName
          unit
          name
          order
          productNumber
          quantity
          material
          panelM2
          woodM3
          tareWeight
          grossWeight
          m3
          stamp
          destination
          packagingStart
          packagingEnd
          packageMethod
          description
          readyToPack
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          cases {
            order
            name
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            phaseId
            isPacked
            netWeight
            grossWeight
            outerLength
            outerWidth
            outerHeight
            m3
            thumbnail
            managementNumber
            isBilled
            invoiceLineId
          }
          note
          woodCost
          lvlCost
          panelCost
          interiorCost
          exteriorCost
          m3Cost
          totalCost
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
          packageType {
            id
            groupId
            pricingId
            name
            description
            publish
            quantity
            material
            stamp
            packageMethod
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            panelM2
            woodM3
            tareWeight
            grossWeight
            note
            standard
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            active
            order
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
          }
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
          areas {
            items {
              id
              productId
              name
              areaType
              order
              description
              createdAt
              updatedAt
            }
            nextToken
          }
          decorations {
            items {
              id
              productId
              type
              name
              slug
              order
              unit
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      projectId
      name
      m3
      case
      shipType
      stockingDate
      packagingDate
      shippingDate
      cutDate
      numImgs
      shipSubFolders {
        id
        name
        numImgs
      }
      stockNumImgs
      stockSubFolders {
        id
        name
        numImgs
      }
      isShipped
      note
      createdAt
      updatedAt
      products {
        items {
          id
          projectId
          packageTypeId
          scheduleId
          userId
          pricingId
          overwriteUnitPrice
          overwriteUnit
          packageTypeName
          quoteLineId
          quoteLineName
          unit
          name
          order
          productNumber
          quantity
          material
          panelM2
          woodM3
          tareWeight
          grossWeight
          m3
          stamp
          destination
          packagingStart
          packagingEnd
          packageMethod
          description
          readyToPack
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          cases {
            order
            name
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            phaseId
            isPacked
            netWeight
            grossWeight
            outerLength
            outerWidth
            outerHeight
            m3
            thumbnail
            managementNumber
            isBilled
            invoiceLineId
          }
          note
          woodCost
          lvlCost
          panelCost
          interiorCost
          exteriorCost
          m3Cost
          totalCost
          elements {
            type
            name
            slug
            value
            expr
            description
            overwriteValue
            cutting
          }
          createdAt
          updatedAt
          packageType {
            id
            groupId
            pricingId
            name
            description
            publish
            quantity
            material
            stamp
            packageMethod
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            panelM2
            woodM3
            tareWeight
            grossWeight
            note
            standard
            woodCost
            lvlCost
            panelCost
            interiorCost
            exteriorCost
            m3Cost
            totalCost
            active
            order
            elements {
              type
              name
              slug
              value
              expr
              description
              overwriteValue
              cutting
            }
            createdAt
            updatedAt
            areas {
              nextToken
            }
            decorations {
              nextToken
            }
            pricing {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
          }
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
          areas {
            items {
              id
              productId
              name
              areaType
              order
              description
              createdAt
              updatedAt
            }
            nextToken
          }
          decorations {
            items {
              id
              productId
              type
              name
              slug
              order
              unit
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      project {
        id
        groupId
        quoteId
        userId
        managerId
        managerName
        quoterId
        quoterName
        operatorId
        operatorName
        accountId
        accountName
        contactId
        contactName
        name
        status
        inquiryDate
        quotationDate
        destinationName
        destination {
          lat
          lng
        }
        confidence
        description
        alert
        cancelled
        cancelReason
        caseMarkNumImgs
        stockNumImgs
        reference
        photoPhases {
          id
          name
          requiresPhoto
          numImgs
        }
        type
        archived
        createdAt
        updatedAt
        account {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        contact {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        schedules {
          items {
            id
            projectId
            name
            m3
            case
            shipType
            stockingDate
            packagingDate
            shippingDate
            cutDate
            numImgs
            shipSubFolders {
              id
              name
              numImgs
            }
            stockNumImgs
            stockSubFolders {
              id
              name
              numImgs
            }
            isShipped
            note
            createdAt
            updatedAt
            products {
              nextToken
            }
            project {
              id
              groupId
              quoteId
              userId
              managerId
              managerName
              quoterId
              quoterName
              operatorId
              operatorName
              accountId
              accountName
              contactId
              contactName
              name
              status
              inquiryDate
              quotationDate
              destinationName
              confidence
              description
              alert
              cancelled
              cancelReason
              caseMarkNumImgs
              stockNumImgs
              reference
              type
              archived
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        user {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        group {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
      }
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      projectId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      projectId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      projectId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const createReportTemplate = /* GraphQL */ `
  mutation CreateReportTemplate(
    $input: CreateReportTemplateInput!
    $condition: ModelReportTemplateConditionInput
  ) {
    createReportTemplate(input: $input, condition: $condition) {
      id
      groupId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      scope
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateReportTemplate = /* GraphQL */ `
  mutation UpdateReportTemplate(
    $input: UpdateReportTemplateInput!
    $condition: ModelReportTemplateConditionInput
  ) {
    updateReportTemplate(input: $input, condition: $condition) {
      id
      groupId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      scope
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteReportTemplate = /* GraphQL */ `
  mutation DeleteReportTemplate(
    $input: DeleteReportTemplateInput!
    $condition: ModelReportTemplateConditionInput
  ) {
    deleteReportTemplate(input: $input, condition: $condition) {
      id
      groupId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      scope
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const createGroupReport = /* GraphQL */ `
  mutation CreateGroupReport(
    $input: CreateGroupReportInput!
    $condition: ModelGroupReportConditionInput
  ) {
    createGroupReport(input: $input, condition: $condition) {
      id
      groupId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      scope
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupReport = /* GraphQL */ `
  mutation UpdateGroupReport(
    $input: UpdateGroupReportInput!
    $condition: ModelGroupReportConditionInput
  ) {
    updateGroupReport(input: $input, condition: $condition) {
      id
      groupId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      scope
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupReport = /* GraphQL */ `
  mutation DeleteGroupReport(
    $input: DeleteGroupReportInput!
    $condition: ModelGroupReportConditionInput
  ) {
    deleteGroupReport(input: $input, condition: $condition) {
      id
      groupId
      name
      template {
        name
        key
        mimeType
      }
      type
      data
      scope
      exportAsPdf
      autoFillFormula
      description
      createdAt
      updatedAt
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      id
      businessRegistrationNumber
      name
      tenantId
      abbreviation
      site
      disinfectantCertificationCode
      billingAddress
      billingPostalCode
      billingCountry
      billingState
      billingCity
      billingStreet
      billingType
      phone
      fax
      customCode
      active
      accountCode
      vendorId
      packagingMaterialProducerRegistrationNumber
      createdAt
      updatedAt
      materials {
        items {
          id
          vendorId
          materialId
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      purchasings {
        items {
          id
          groupId
          vendorId
          materialId
          purchasingDate
          startDate
          unitPurchasingCost
          purchaseUnit
          quantity
          totalCost
          unitCost
          costUnit
          customFields {
            label
            value
          }
          note
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      id
      businessRegistrationNumber
      name
      tenantId
      abbreviation
      site
      disinfectantCertificationCode
      billingAddress
      billingPostalCode
      billingCountry
      billingState
      billingCity
      billingStreet
      billingType
      phone
      fax
      customCode
      active
      accountCode
      vendorId
      packagingMaterialProducerRegistrationNumber
      createdAt
      updatedAt
      materials {
        items {
          id
          vendorId
          materialId
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      purchasings {
        items {
          id
          groupId
          vendorId
          materialId
          purchasingDate
          startDate
          unitPurchasingCost
          purchaseUnit
          quantity
          totalCost
          unitCost
          costUnit
          customFields {
            label
            value
          }
          note
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      id
      businessRegistrationNumber
      name
      tenantId
      abbreviation
      site
      disinfectantCertificationCode
      billingAddress
      billingPostalCode
      billingCountry
      billingState
      billingCity
      billingStreet
      billingType
      phone
      fax
      customCode
      active
      accountCode
      vendorId
      packagingMaterialProducerRegistrationNumber
      createdAt
      updatedAt
      materials {
        items {
          id
          vendorId
          materialId
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      purchasings {
        items {
          id
          groupId
          vendorId
          materialId
          purchasingDate
          startDate
          unitPurchasingCost
          purchaseUnit
          quantity
          totalCost
          unitCost
          costUnit
          customFields {
            label
            value
          }
          note
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createVendorsMaterials = /* GraphQL */ `
  mutation CreateVendorsMaterials(
    $input: CreateVendorsMaterialsInput!
    $condition: ModelVendorsMaterialsConditionInput
  ) {
    createVendorsMaterials(input: $input, condition: $condition) {
      id
      vendorId
      materialId
      createdAt
      updatedAt
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      material {
        id
        groupId
        materialTypeId
        speciesId
        name
        purchaseUnit
        lossRate
        weight
        costCalculation
        defaultUnitCost
        unit
        length
        lengthApplicable
        lengthLocked
        lengthNominal
        width
        widthApplicable
        widthLocked
        widthNominal
        height
        heightApplicable
        heightLocked
        heightNominal
        active
        refBhhFace
        refBhhEdge
        z1402BeamOrder
        z1403BeamSupportOrder
        z1403BeamFaceOrder
        z1403BeamEdgeOrder
        z1410BeamSupportOrder
        z1410BeamFaceOrder
        z1410BeamEdgeOrder
        createdAt
        updatedAt
        vendors {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        species {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        materialType {
          id
          groupId
          name
          description
          type
          isWood
          isPanel
          summaryReportTarget
          customFields {
            name
            type
            required
          }
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateVendorsMaterials = /* GraphQL */ `
  mutation UpdateVendorsMaterials(
    $input: UpdateVendorsMaterialsInput!
    $condition: ModelVendorsMaterialsConditionInput
  ) {
    updateVendorsMaterials(input: $input, condition: $condition) {
      id
      vendorId
      materialId
      createdAt
      updatedAt
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      material {
        id
        groupId
        materialTypeId
        speciesId
        name
        purchaseUnit
        lossRate
        weight
        costCalculation
        defaultUnitCost
        unit
        length
        lengthApplicable
        lengthLocked
        lengthNominal
        width
        widthApplicable
        widthLocked
        widthNominal
        height
        heightApplicable
        heightLocked
        heightNominal
        active
        refBhhFace
        refBhhEdge
        z1402BeamOrder
        z1403BeamSupportOrder
        z1403BeamFaceOrder
        z1403BeamEdgeOrder
        z1410BeamSupportOrder
        z1410BeamFaceOrder
        z1410BeamEdgeOrder
        createdAt
        updatedAt
        vendors {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        species {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        materialType {
          id
          groupId
          name
          description
          type
          isWood
          isPanel
          summaryReportTarget
          customFields {
            name
            type
            required
          }
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteVendorsMaterials = /* GraphQL */ `
  mutation DeleteVendorsMaterials(
    $input: DeleteVendorsMaterialsInput!
    $condition: ModelVendorsMaterialsConditionInput
  ) {
    deleteVendorsMaterials(input: $input, condition: $condition) {
      id
      vendorId
      materialId
      createdAt
      updatedAt
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      material {
        id
        groupId
        materialTypeId
        speciesId
        name
        purchaseUnit
        lossRate
        weight
        costCalculation
        defaultUnitCost
        unit
        length
        lengthApplicable
        lengthLocked
        lengthNominal
        width
        widthApplicable
        widthLocked
        widthNominal
        height
        heightApplicable
        heightLocked
        heightNominal
        active
        refBhhFace
        refBhhEdge
        z1402BeamOrder
        z1403BeamSupportOrder
        z1403BeamFaceOrder
        z1403BeamEdgeOrder
        z1410BeamSupportOrder
        z1410BeamFaceOrder
        z1410BeamEdgeOrder
        createdAt
        updatedAt
        vendors {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        species {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        materialType {
          id
          groupId
          name
          description
          type
          isWood
          isPanel
          summaryReportTarget
          customFields {
            name
            type
            required
          }
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createMaterial = /* GraphQL */ `
  mutation CreateMaterial(
    $input: CreateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    createMaterial(input: $input, condition: $condition) {
      id
      groupId
      materialTypeId
      speciesId
      name
      purchaseUnit
      lossRate
      weight
      costCalculation
      defaultUnitCost
      unit
      length
      lengthApplicable
      lengthLocked
      lengthNominal
      width
      widthApplicable
      widthLocked
      widthNominal
      height
      heightApplicable
      heightLocked
      heightNominal
      active
      refBhhFace
      refBhhEdge
      z1402BeamOrder
      z1403BeamSupportOrder
      z1403BeamFaceOrder
      z1403BeamEdgeOrder
      z1410BeamSupportOrder
      z1410BeamFaceOrder
      z1410BeamEdgeOrder
      createdAt
      updatedAt
      vendors {
        items {
          id
          vendorId
          materialId
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      species {
        id
        groupId
        name
        faceMPa
        edgeMPa
        description
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      materialType {
        id
        groupId
        name
        description
        type
        isWood
        isPanel
        summaryReportTarget
        customFields {
          name
          type
          required
        }
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      purchasings {
        items {
          id
          groupId
          vendorId
          materialId
          purchasingDate
          startDate
          unitPurchasingCost
          purchaseUnit
          quantity
          totalCost
          unitCost
          costUnit
          customFields {
            label
            value
          }
          note
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateMaterial = /* GraphQL */ `
  mutation UpdateMaterial(
    $input: UpdateMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    updateMaterial(input: $input, condition: $condition) {
      id
      groupId
      materialTypeId
      speciesId
      name
      purchaseUnit
      lossRate
      weight
      costCalculation
      defaultUnitCost
      unit
      length
      lengthApplicable
      lengthLocked
      lengthNominal
      width
      widthApplicable
      widthLocked
      widthNominal
      height
      heightApplicable
      heightLocked
      heightNominal
      active
      refBhhFace
      refBhhEdge
      z1402BeamOrder
      z1403BeamSupportOrder
      z1403BeamFaceOrder
      z1403BeamEdgeOrder
      z1410BeamSupportOrder
      z1410BeamFaceOrder
      z1410BeamEdgeOrder
      createdAt
      updatedAt
      vendors {
        items {
          id
          vendorId
          materialId
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      species {
        id
        groupId
        name
        faceMPa
        edgeMPa
        description
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      materialType {
        id
        groupId
        name
        description
        type
        isWood
        isPanel
        summaryReportTarget
        customFields {
          name
          type
          required
        }
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      purchasings {
        items {
          id
          groupId
          vendorId
          materialId
          purchasingDate
          startDate
          unitPurchasingCost
          purchaseUnit
          quantity
          totalCost
          unitCost
          costUnit
          customFields {
            label
            value
          }
          note
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteMaterial = /* GraphQL */ `
  mutation DeleteMaterial(
    $input: DeleteMaterialInput!
    $condition: ModelMaterialConditionInput
  ) {
    deleteMaterial(input: $input, condition: $condition) {
      id
      groupId
      materialTypeId
      speciesId
      name
      purchaseUnit
      lossRate
      weight
      costCalculation
      defaultUnitCost
      unit
      length
      lengthApplicable
      lengthLocked
      lengthNominal
      width
      widthApplicable
      widthLocked
      widthNominal
      height
      heightApplicable
      heightLocked
      heightNominal
      active
      refBhhFace
      refBhhEdge
      z1402BeamOrder
      z1403BeamSupportOrder
      z1403BeamFaceOrder
      z1403BeamEdgeOrder
      z1410BeamSupportOrder
      z1410BeamFaceOrder
      z1410BeamEdgeOrder
      createdAt
      updatedAt
      vendors {
        items {
          id
          vendorId
          materialId
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
      species {
        id
        groupId
        name
        faceMPa
        edgeMPa
        description
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      materialType {
        id
        groupId
        name
        description
        type
        isWood
        isPanel
        summaryReportTarget
        customFields {
          name
          type
          required
        }
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      purchasings {
        items {
          id
          groupId
          vendorId
          materialId
          purchasingDate
          startDate
          unitPurchasingCost
          purchaseUnit
          quantity
          totalCost
          unitCost
          costUnit
          customFields {
            label
            value
          }
          note
          createdAt
          updatedAt
          vendor {
            id
            businessRegistrationNumber
            name
            tenantId
            abbreviation
            site
            disinfectantCertificationCode
            billingAddress
            billingPostalCode
            billingCountry
            billingState
            billingCity
            billingStreet
            billingType
            phone
            fax
            customCode
            active
            accountCode
            vendorId
            packagingMaterialProducerRegistrationNumber
            createdAt
            updatedAt
            materials {
              nextToken
            }
            purchasings {
              nextToken
            }
          }
          material {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createSpecies = /* GraphQL */ `
  mutation CreateSpecies(
    $input: CreateSpeciesInput!
    $condition: ModelSpeciesConditionInput
  ) {
    createSpecies(input: $input, condition: $condition) {
      id
      groupId
      name
      faceMPa
      edgeMPa
      description
      active
      createdAt
      updatedAt
      materials {
        items {
          id
          groupId
          materialTypeId
          speciesId
          name
          purchaseUnit
          lossRate
          weight
          costCalculation
          defaultUnitCost
          unit
          length
          lengthApplicable
          lengthLocked
          lengthNominal
          width
          widthApplicable
          widthLocked
          widthNominal
          height
          heightApplicable
          heightLocked
          heightNominal
          active
          refBhhFace
          refBhhEdge
          z1402BeamOrder
          z1403BeamSupportOrder
          z1403BeamFaceOrder
          z1403BeamEdgeOrder
          z1410BeamSupportOrder
          z1410BeamFaceOrder
          z1410BeamEdgeOrder
          createdAt
          updatedAt
          vendors {
            items {
              id
              vendorId
              materialId
              createdAt
              updatedAt
            }
            nextToken
          }
          species {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          materialType {
            id
            groupId
            name
            description
            type
            isWood
            isPanel
            summaryReportTarget
            customFields {
              name
              type
              required
            }
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          purchasings {
            items {
              id
              groupId
              vendorId
              materialId
              purchasingDate
              startDate
              unitPurchasingCost
              purchaseUnit
              quantity
              totalCost
              unitCost
              costUnit
              note
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateSpecies = /* GraphQL */ `
  mutation UpdateSpecies(
    $input: UpdateSpeciesInput!
    $condition: ModelSpeciesConditionInput
  ) {
    updateSpecies(input: $input, condition: $condition) {
      id
      groupId
      name
      faceMPa
      edgeMPa
      description
      active
      createdAt
      updatedAt
      materials {
        items {
          id
          groupId
          materialTypeId
          speciesId
          name
          purchaseUnit
          lossRate
          weight
          costCalculation
          defaultUnitCost
          unit
          length
          lengthApplicable
          lengthLocked
          lengthNominal
          width
          widthApplicable
          widthLocked
          widthNominal
          height
          heightApplicable
          heightLocked
          heightNominal
          active
          refBhhFace
          refBhhEdge
          z1402BeamOrder
          z1403BeamSupportOrder
          z1403BeamFaceOrder
          z1403BeamEdgeOrder
          z1410BeamSupportOrder
          z1410BeamFaceOrder
          z1410BeamEdgeOrder
          createdAt
          updatedAt
          vendors {
            items {
              id
              vendorId
              materialId
              createdAt
              updatedAt
            }
            nextToken
          }
          species {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          materialType {
            id
            groupId
            name
            description
            type
            isWood
            isPanel
            summaryReportTarget
            customFields {
              name
              type
              required
            }
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          purchasings {
            items {
              id
              groupId
              vendorId
              materialId
              purchasingDate
              startDate
              unitPurchasingCost
              purchaseUnit
              quantity
              totalCost
              unitCost
              costUnit
              note
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteSpecies = /* GraphQL */ `
  mutation DeleteSpecies(
    $input: DeleteSpeciesInput!
    $condition: ModelSpeciesConditionInput
  ) {
    deleteSpecies(input: $input, condition: $condition) {
      id
      groupId
      name
      faceMPa
      edgeMPa
      description
      active
      createdAt
      updatedAt
      materials {
        items {
          id
          groupId
          materialTypeId
          speciesId
          name
          purchaseUnit
          lossRate
          weight
          costCalculation
          defaultUnitCost
          unit
          length
          lengthApplicable
          lengthLocked
          lengthNominal
          width
          widthApplicable
          widthLocked
          widthNominal
          height
          heightApplicable
          heightLocked
          heightNominal
          active
          refBhhFace
          refBhhEdge
          z1402BeamOrder
          z1403BeamSupportOrder
          z1403BeamFaceOrder
          z1403BeamEdgeOrder
          z1410BeamSupportOrder
          z1410BeamFaceOrder
          z1410BeamEdgeOrder
          createdAt
          updatedAt
          vendors {
            items {
              id
              vendorId
              materialId
              createdAt
              updatedAt
            }
            nextToken
          }
          species {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          materialType {
            id
            groupId
            name
            description
            type
            isWood
            isPanel
            summaryReportTarget
            customFields {
              name
              type
              required
            }
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          purchasings {
            items {
              id
              groupId
              vendorId
              materialId
              purchasingDate
              startDate
              unitPurchasingCost
              purchaseUnit
              quantity
              totalCost
              unitCost
              costUnit
              note
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createMaterialType = /* GraphQL */ `
  mutation CreateMaterialType(
    $input: CreateMaterialTypeInput!
    $condition: ModelMaterialTypeConditionInput
  ) {
    createMaterialType(input: $input, condition: $condition) {
      id
      groupId
      name
      description
      type
      isWood
      isPanel
      summaryReportTarget
      customFields {
        name
        type
        required
      }
      active
      createdAt
      updatedAt
      materials {
        items {
          id
          groupId
          materialTypeId
          speciesId
          name
          purchaseUnit
          lossRate
          weight
          costCalculation
          defaultUnitCost
          unit
          length
          lengthApplicable
          lengthLocked
          lengthNominal
          width
          widthApplicable
          widthLocked
          widthNominal
          height
          heightApplicable
          heightLocked
          heightNominal
          active
          refBhhFace
          refBhhEdge
          z1402BeamOrder
          z1403BeamSupportOrder
          z1403BeamFaceOrder
          z1403BeamEdgeOrder
          z1410BeamSupportOrder
          z1410BeamFaceOrder
          z1410BeamEdgeOrder
          createdAt
          updatedAt
          vendors {
            items {
              id
              vendorId
              materialId
              createdAt
              updatedAt
            }
            nextToken
          }
          species {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          materialType {
            id
            groupId
            name
            description
            type
            isWood
            isPanel
            summaryReportTarget
            customFields {
              name
              type
              required
            }
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          purchasings {
            items {
              id
              groupId
              vendorId
              materialId
              purchasingDate
              startDate
              unitPurchasingCost
              purchaseUnit
              quantity
              totalCost
              unitCost
              costUnit
              note
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const updateMaterialType = /* GraphQL */ `
  mutation UpdateMaterialType(
    $input: UpdateMaterialTypeInput!
    $condition: ModelMaterialTypeConditionInput
  ) {
    updateMaterialType(input: $input, condition: $condition) {
      id
      groupId
      name
      description
      type
      isWood
      isPanel
      summaryReportTarget
      customFields {
        name
        type
        required
      }
      active
      createdAt
      updatedAt
      materials {
        items {
          id
          groupId
          materialTypeId
          speciesId
          name
          purchaseUnit
          lossRate
          weight
          costCalculation
          defaultUnitCost
          unit
          length
          lengthApplicable
          lengthLocked
          lengthNominal
          width
          widthApplicable
          widthLocked
          widthNominal
          height
          heightApplicable
          heightLocked
          heightNominal
          active
          refBhhFace
          refBhhEdge
          z1402BeamOrder
          z1403BeamSupportOrder
          z1403BeamFaceOrder
          z1403BeamEdgeOrder
          z1410BeamSupportOrder
          z1410BeamFaceOrder
          z1410BeamEdgeOrder
          createdAt
          updatedAt
          vendors {
            items {
              id
              vendorId
              materialId
              createdAt
              updatedAt
            }
            nextToken
          }
          species {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          materialType {
            id
            groupId
            name
            description
            type
            isWood
            isPanel
            summaryReportTarget
            customFields {
              name
              type
              required
            }
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          purchasings {
            items {
              id
              groupId
              vendorId
              materialId
              purchasingDate
              startDate
              unitPurchasingCost
              purchaseUnit
              quantity
              totalCost
              unitCost
              costUnit
              note
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteMaterialType = /* GraphQL */ `
  mutation DeleteMaterialType(
    $input: DeleteMaterialTypeInput!
    $condition: ModelMaterialTypeConditionInput
  ) {
    deleteMaterialType(input: $input, condition: $condition) {
      id
      groupId
      name
      description
      type
      isWood
      isPanel
      summaryReportTarget
      customFields {
        name
        type
        required
      }
      active
      createdAt
      updatedAt
      materials {
        items {
          id
          groupId
          materialTypeId
          speciesId
          name
          purchaseUnit
          lossRate
          weight
          costCalculation
          defaultUnitCost
          unit
          length
          lengthApplicable
          lengthLocked
          lengthNominal
          width
          widthApplicable
          widthLocked
          widthNominal
          height
          heightApplicable
          heightLocked
          heightNominal
          active
          refBhhFace
          refBhhEdge
          z1402BeamOrder
          z1403BeamSupportOrder
          z1403BeamFaceOrder
          z1403BeamEdgeOrder
          z1410BeamSupportOrder
          z1410BeamFaceOrder
          z1410BeamEdgeOrder
          createdAt
          updatedAt
          vendors {
            items {
              id
              vendorId
              materialId
              createdAt
              updatedAt
            }
            nextToken
          }
          species {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          materialType {
            id
            groupId
            name
            description
            type
            isWood
            isPanel
            summaryReportTarget
            customFields {
              name
              type
              required
            }
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          purchasings {
            items {
              id
              groupId
              vendorId
              materialId
              purchasingDate
              startDate
              unitPurchasingCost
              purchaseUnit
              quantity
              totalCost
              unitCost
              costUnit
              note
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const createPurchasing = /* GraphQL */ `
  mutation CreatePurchasing(
    $input: CreatePurchasingInput!
    $condition: ModelPurchasingConditionInput
  ) {
    createPurchasing(input: $input, condition: $condition) {
      id
      groupId
      vendorId
      materialId
      purchasingDate
      startDate
      unitPurchasingCost
      purchaseUnit
      quantity
      totalCost
      unitCost
      costUnit
      customFields {
        label
        value
      }
      note
      createdAt
      updatedAt
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      material {
        id
        groupId
        materialTypeId
        speciesId
        name
        purchaseUnit
        lossRate
        weight
        costCalculation
        defaultUnitCost
        unit
        length
        lengthApplicable
        lengthLocked
        lengthNominal
        width
        widthApplicable
        widthLocked
        widthNominal
        height
        heightApplicable
        heightLocked
        heightNominal
        active
        refBhhFace
        refBhhEdge
        z1402BeamOrder
        z1403BeamSupportOrder
        z1403BeamFaceOrder
        z1403BeamEdgeOrder
        z1410BeamSupportOrder
        z1410BeamFaceOrder
        z1410BeamEdgeOrder
        createdAt
        updatedAt
        vendors {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        species {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        materialType {
          id
          groupId
          name
          description
          type
          isWood
          isPanel
          summaryReportTarget
          customFields {
            name
            type
            required
          }
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updatePurchasing = /* GraphQL */ `
  mutation UpdatePurchasing(
    $input: UpdatePurchasingInput!
    $condition: ModelPurchasingConditionInput
  ) {
    updatePurchasing(input: $input, condition: $condition) {
      id
      groupId
      vendorId
      materialId
      purchasingDate
      startDate
      unitPurchasingCost
      purchaseUnit
      quantity
      totalCost
      unitCost
      costUnit
      customFields {
        label
        value
      }
      note
      createdAt
      updatedAt
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      material {
        id
        groupId
        materialTypeId
        speciesId
        name
        purchaseUnit
        lossRate
        weight
        costCalculation
        defaultUnitCost
        unit
        length
        lengthApplicable
        lengthLocked
        lengthNominal
        width
        widthApplicable
        widthLocked
        widthNominal
        height
        heightApplicable
        heightLocked
        heightNominal
        active
        refBhhFace
        refBhhEdge
        z1402BeamOrder
        z1403BeamSupportOrder
        z1403BeamFaceOrder
        z1403BeamEdgeOrder
        z1410BeamSupportOrder
        z1410BeamFaceOrder
        z1410BeamEdgeOrder
        createdAt
        updatedAt
        vendors {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        species {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        materialType {
          id
          groupId
          name
          description
          type
          isWood
          isPanel
          summaryReportTarget
          customFields {
            name
            type
            required
          }
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deletePurchasing = /* GraphQL */ `
  mutation DeletePurchasing(
    $input: DeletePurchasingInput!
    $condition: ModelPurchasingConditionInput
  ) {
    deletePurchasing(input: $input, condition: $condition) {
      id
      groupId
      vendorId
      materialId
      purchasingDate
      startDate
      unitPurchasingCost
      purchaseUnit
      quantity
      totalCost
      unitCost
      costUnit
      customFields {
        label
        value
      }
      note
      createdAt
      updatedAt
      vendor {
        id
        businessRegistrationNumber
        name
        tenantId
        abbreviation
        site
        disinfectantCertificationCode
        billingAddress
        billingPostalCode
        billingCountry
        billingState
        billingCity
        billingStreet
        billingType
        phone
        fax
        customCode
        active
        accountCode
        vendorId
        packagingMaterialProducerRegistrationNumber
        createdAt
        updatedAt
        materials {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      material {
        id
        groupId
        materialTypeId
        speciesId
        name
        purchaseUnit
        lossRate
        weight
        costCalculation
        defaultUnitCost
        unit
        length
        lengthApplicable
        lengthLocked
        lengthNominal
        width
        widthApplicable
        widthLocked
        widthNominal
        height
        heightApplicable
        heightLocked
        heightNominal
        active
        refBhhFace
        refBhhEdge
        z1402BeamOrder
        z1403BeamSupportOrder
        z1403BeamFaceOrder
        z1403BeamEdgeOrder
        z1410BeamSupportOrder
        z1410BeamFaceOrder
        z1410BeamEdgeOrder
        createdAt
        updatedAt
        vendors {
          items {
            id
            vendorId
            materialId
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        species {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        materialType {
          id
          groupId
          name
          description
          type
          isWood
          isPanel
          summaryReportTarget
          customFields {
            name
            type
            required
          }
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        purchasings {
          items {
            id
            groupId
            vendorId
            materialId
            purchasingDate
            startDate
            unitPurchasingCost
            purchaseUnit
            quantity
            totalCost
            unitCost
            costUnit
            customFields {
              label
              value
            }
            note
            createdAt
            updatedAt
            vendor {
              id
              businessRegistrationNumber
              name
              tenantId
              abbreviation
              site
              disinfectantCertificationCode
              billingAddress
              billingPostalCode
              billingCountry
              billingState
              billingCity
              billingStreet
              billingType
              phone
              fax
              customCode
              active
              accountCode
              vendorId
              packagingMaterialProducerRegistrationNumber
              createdAt
              updatedAt
            }
            material {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      id
      projectId
      name
      quoteNumber
      linkProduct
      order
      orderPlaced
      packRevenue
      packEstimatedCost
      packEstimatedProfit
      packTotalNetWeight
      packTotalNumOfCases
      packTotalGrossWeight
      packTotalM3
      revenue
      estimatedCost
      estimatedProfit
      totalNumOfCases
      totalNetWeight
      totalGrossWeight
      totalM3
      greeting
      paymentMethod
      destination
      dueDate
      expiryDate
      description
      groupName
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      createdAt
      updatedAt
      quoteLines {
        items {
          id
          quoteId
          pricingId
          packageTypeId
          productId
          quoteProductId
          productName
          pricingName
          packageTypeName
          showInQuoteSummary
          name
          index
          notes
          quantity
          autocalculate
          amount
          minimumAmount
          actualAmount
          order
          unitNW
          NW
          productLength
          productWidth
          productHeight
          outerLength
          outerWidth
          outerHeight
          estimatedUnitM3
          estimatedM3
          estimatedUnitGrossWeight
          estimatedGrossWeight
          estimatedUnitCost
          estimatedCost
          estimatedProfit
          estimatedWood
          estimatedLvl
          estimatedPanel
          estimatedInterior
          estimatedExterior
          estimatedCostM3
          estimatedOther
          unitPrice
          subtotal
          total
          unit
          taxType
          taxRate
          salesType
          costDetail
          createdAt
          updatedAt
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      id
      projectId
      name
      quoteNumber
      linkProduct
      order
      orderPlaced
      packRevenue
      packEstimatedCost
      packEstimatedProfit
      packTotalNetWeight
      packTotalNumOfCases
      packTotalGrossWeight
      packTotalM3
      revenue
      estimatedCost
      estimatedProfit
      totalNumOfCases
      totalNetWeight
      totalGrossWeight
      totalM3
      greeting
      paymentMethod
      destination
      dueDate
      expiryDate
      description
      groupName
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      createdAt
      updatedAt
      quoteLines {
        items {
          id
          quoteId
          pricingId
          packageTypeId
          productId
          quoteProductId
          productName
          pricingName
          packageTypeName
          showInQuoteSummary
          name
          index
          notes
          quantity
          autocalculate
          amount
          minimumAmount
          actualAmount
          order
          unitNW
          NW
          productLength
          productWidth
          productHeight
          outerLength
          outerWidth
          outerHeight
          estimatedUnitM3
          estimatedM3
          estimatedUnitGrossWeight
          estimatedGrossWeight
          estimatedUnitCost
          estimatedCost
          estimatedProfit
          estimatedWood
          estimatedLvl
          estimatedPanel
          estimatedInterior
          estimatedExterior
          estimatedCostM3
          estimatedOther
          unitPrice
          subtotal
          total
          unit
          taxType
          taxRate
          salesType
          costDetail
          createdAt
          updatedAt
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      id
      projectId
      name
      quoteNumber
      linkProduct
      order
      orderPlaced
      packRevenue
      packEstimatedCost
      packEstimatedProfit
      packTotalNetWeight
      packTotalNumOfCases
      packTotalGrossWeight
      packTotalM3
      revenue
      estimatedCost
      estimatedProfit
      totalNumOfCases
      totalNetWeight
      totalGrossWeight
      totalM3
      greeting
      paymentMethod
      destination
      dueDate
      expiryDate
      description
      groupName
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      phone
      fax
      createdAt
      updatedAt
      quoteLines {
        items {
          id
          quoteId
          pricingId
          packageTypeId
          productId
          quoteProductId
          productName
          pricingName
          packageTypeName
          showInQuoteSummary
          name
          index
          notes
          quantity
          autocalculate
          amount
          minimumAmount
          actualAmount
          order
          unitNW
          NW
          productLength
          productWidth
          productHeight
          outerLength
          outerWidth
          outerHeight
          estimatedUnitM3
          estimatedM3
          estimatedUnitGrossWeight
          estimatedGrossWeight
          estimatedUnitCost
          estimatedCost
          estimatedProfit
          estimatedWood
          estimatedLvl
          estimatedPanel
          estimatedInterior
          estimatedExterior
          estimatedCostM3
          estimatedOther
          unitPrice
          subtotal
          total
          unit
          taxType
          taxRate
          salesType
          costDetail
          createdAt
          updatedAt
          pricing {
            id
            groupId
            priceTypeId
            packageTypeId
            isDefault
            name
            packageTypeName
            unitPrice
            unit
            autoCalculate
            minimumAmount
            taxType
            sizeDependent
            pricesBySize {
              minSize
              maxSize
              unitPrice
            }
            accountSpecific
            accounts {
              key
              label
            }
            active
            description
            createdAt
            updatedAt
            priceType {
              id
              groupId
              name
              description
              showInQuoteSummary
              active
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createQuoteLine = /* GraphQL */ `
  mutation CreateQuoteLine(
    $input: CreateQuoteLineInput!
    $condition: ModelQuoteLineConditionInput
  ) {
    createQuoteLine(input: $input, condition: $condition) {
      id
      quoteId
      pricingId
      packageTypeId
      productId
      quoteProductId
      productName
      pricingName
      packageTypeName
      showInQuoteSummary
      name
      index
      notes
      quantity
      autocalculate
      amount
      minimumAmount
      actualAmount
      order
      unitNW
      NW
      productLength
      productWidth
      productHeight
      outerLength
      outerWidth
      outerHeight
      estimatedUnitM3
      estimatedM3
      estimatedUnitGrossWeight
      estimatedGrossWeight
      estimatedUnitCost
      estimatedCost
      estimatedProfit
      estimatedWood
      estimatedLvl
      estimatedPanel
      estimatedInterior
      estimatedExterior
      estimatedCostM3
      estimatedOther
      unitPrice
      subtotal
      total
      unit
      taxType
      taxRate
      salesType
      costDetail
      createdAt
      updatedAt
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updateQuoteLine = /* GraphQL */ `
  mutation UpdateQuoteLine(
    $input: UpdateQuoteLineInput!
    $condition: ModelQuoteLineConditionInput
  ) {
    updateQuoteLine(input: $input, condition: $condition) {
      id
      quoteId
      pricingId
      packageTypeId
      productId
      quoteProductId
      productName
      pricingName
      packageTypeName
      showInQuoteSummary
      name
      index
      notes
      quantity
      autocalculate
      amount
      minimumAmount
      actualAmount
      order
      unitNW
      NW
      productLength
      productWidth
      productHeight
      outerLength
      outerWidth
      outerHeight
      estimatedUnitM3
      estimatedM3
      estimatedUnitGrossWeight
      estimatedGrossWeight
      estimatedUnitCost
      estimatedCost
      estimatedProfit
      estimatedWood
      estimatedLvl
      estimatedPanel
      estimatedInterior
      estimatedExterior
      estimatedCostM3
      estimatedOther
      unitPrice
      subtotal
      total
      unit
      taxType
      taxRate
      salesType
      costDetail
      createdAt
      updatedAt
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteQuoteLine = /* GraphQL */ `
  mutation DeleteQuoteLine(
    $input: DeleteQuoteLineInput!
    $condition: ModelQuoteLineConditionInput
  ) {
    deleteQuoteLine(input: $input, condition: $condition) {
      id
      quoteId
      pricingId
      packageTypeId
      productId
      quoteProductId
      productName
      pricingName
      packageTypeName
      showInQuoteSummary
      name
      index
      notes
      quantity
      autocalculate
      amount
      minimumAmount
      actualAmount
      order
      unitNW
      NW
      productLength
      productWidth
      productHeight
      outerLength
      outerWidth
      outerHeight
      estimatedUnitM3
      estimatedM3
      estimatedUnitGrossWeight
      estimatedGrossWeight
      estimatedUnitCost
      estimatedCost
      estimatedProfit
      estimatedWood
      estimatedLvl
      estimatedPanel
      estimatedInterior
      estimatedExterior
      estimatedCostM3
      estimatedOther
      unitPrice
      subtotal
      total
      unit
      taxType
      taxRate
      salesType
      costDetail
      createdAt
      updatedAt
      pricing {
        id
        groupId
        priceTypeId
        packageTypeId
        isDefault
        name
        packageTypeName
        unitPrice
        unit
        autoCalculate
        minimumAmount
        taxType
        sizeDependent
        pricesBySize {
          minSize
          maxSize
          unitPrice
        }
        accountSpecific
        accounts {
          key
          label
        }
        active
        description
        createdAt
        updatedAt
        priceType {
          id
          groupId
          name
          description
          showInQuoteSummary
          active
          createdAt
          updatedAt
          pricings {
            items {
              id
              groupId
              priceTypeId
              packageTypeId
              isDefault
              name
              packageTypeName
              unitPrice
              unit
              autoCalculate
              minimumAmount
              taxType
              sizeDependent
              accountSpecific
              active
              description
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createSFObject = /* GraphQL */ `
  mutation CreateSFObject(
    $input: CreateSFObjectInput!
    $condition: ModelSFObjectConditionInput
  ) {
    createSFObject(input: $input, condition: $condition) {
      id
      name
      tenantId
      label
      sfObjectName
      editable
      condition
      fields {
        name
        label
        apiName
        type
        required
        unique
        primary
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSFObject = /* GraphQL */ `
  mutation UpdateSFObject(
    $input: UpdateSFObjectInput!
    $condition: ModelSFObjectConditionInput
  ) {
    updateSFObject(input: $input, condition: $condition) {
      id
      name
      tenantId
      label
      sfObjectName
      editable
      condition
      fields {
        name
        label
        apiName
        type
        required
        unique
        primary
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSFObject = /* GraphQL */ `
  mutation DeleteSFObject(
    $input: DeleteSFObjectInput!
    $condition: ModelSFObjectConditionInput
  ) {
    deleteSFObject(input: $input, condition: $condition) {
      id
      name
      tenantId
      label
      sfObjectName
      editable
      condition
      fields {
        name
        label
        apiName
        type
        required
        unique
        primary
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSyncLog = /* GraphQL */ `
  mutation CreateSyncLog(
    $input: CreateSyncLogInput!
    $condition: ModelSyncLogConditionInput
  ) {
    createSyncLog(input: $input, condition: $condition) {
      id
      status
      numberOfNewRecords
      numberOfDeletedRecords
      numberOfUpdatedRecords
      message
      sFObjectId
      createdAt
      updatedAt
      sFObject {
        id
        name
        tenantId
        label
        sfObjectName
        editable
        condition
        fields {
          name
          label
          apiName
          type
          required
          unique
          primary
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSyncLog = /* GraphQL */ `
  mutation UpdateSyncLog(
    $input: UpdateSyncLogInput!
    $condition: ModelSyncLogConditionInput
  ) {
    updateSyncLog(input: $input, condition: $condition) {
      id
      status
      numberOfNewRecords
      numberOfDeletedRecords
      numberOfUpdatedRecords
      message
      sFObjectId
      createdAt
      updatedAt
      sFObject {
        id
        name
        tenantId
        label
        sfObjectName
        editable
        condition
        fields {
          name
          label
          apiName
          type
          required
          unique
          primary
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSyncLog = /* GraphQL */ `
  mutation DeleteSyncLog(
    $input: DeleteSyncLogInput!
    $condition: ModelSyncLogConditionInput
  ) {
    deleteSyncLog(input: $input, condition: $condition) {
      id
      status
      numberOfNewRecords
      numberOfDeletedRecords
      numberOfUpdatedRecords
      message
      sFObjectId
      createdAt
      updatedAt
      sFObject {
        id
        name
        tenantId
        label
        sfObjectName
        editable
        condition
        fields {
          name
          label
          apiName
          type
          required
          unique
          primary
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAccessLog = /* GraphQL */ `
  mutation CreateAccessLog(
    $input: CreateAccessLogInput!
    $condition: ModelAccessLogConditionInput
  ) {
    createAccessLog(input: $input, condition: $condition) {
      id
      device
      ipAddress
      description
      tenantUserId
      createdAt
      updatedAt
    }
  }
`;
export const updateAccessLog = /* GraphQL */ `
  mutation UpdateAccessLog(
    $input: UpdateAccessLogInput!
    $condition: ModelAccessLogConditionInput
  ) {
    updateAccessLog(input: $input, condition: $condition) {
      id
      device
      ipAddress
      description
      tenantUserId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccessLog = /* GraphQL */ `
  mutation DeleteAccessLog(
    $input: DeleteAccessLogInput!
    $condition: ModelAccessLogConditionInput
  ) {
    deleteAccessLog(input: $input, condition: $condition) {
      id
      device
      ipAddress
      description
      tenantUserId
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      externalId
      name
      firstName
      lastName
      email
      showTutorial
      invited
      verified
      fontSize
      language
      receiveEmailNotification
      theme
      useMfa
      customId
      tenantId
      isAdmin
      createdAt
      updatedAt
      groups {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenants {
        items {
          id
          userId
          tenantId
          isAdmin
          license
          hidden
          name
          firstName
          lastName
          configuration {
            projectFilter {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
          }
          customId
          active
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      externalId
      name
      firstName
      lastName
      email
      showTutorial
      invited
      verified
      fontSize
      language
      receiveEmailNotification
      theme
      useMfa
      customId
      tenantId
      isAdmin
      createdAt
      updatedAt
      groups {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenants {
        items {
          id
          userId
          tenantId
          isAdmin
          license
          hidden
          name
          firstName
          lastName
          configuration {
            projectFilter {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
          }
          customId
          active
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      externalId
      name
      firstName
      lastName
      email
      showTutorial
      invited
      verified
      fontSize
      language
      receiveEmailNotification
      theme
      useMfa
      customId
      tenantId
      isAdmin
      createdAt
      updatedAt
      groups {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenants {
        items {
          id
          userId
          tenantId
          isAdmin
          license
          hidden
          name
          firstName
          lastName
          configuration {
            projectFilter {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
          }
          customId
          active
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      phone
      fax
      email
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      quoteInfo {
        greeting
        paymentMethod
        destination
        dueDate
        expiryDate
        description
      }
      bank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      taxRate
      maxInventoryM3
      prefix
      fiscalYearStartMonth
      salesCutoffDay
      revenueRecognitionCriterion
      mainSpeciesId
      mainSpeciesName
      mainSpeciesFaceMPa
      mainSpeciesEdgeMPa
      tenantId
      createdAt
      updatedAt
      holidays {
        items {
          id
          groupId
          name
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      mainSpecies {
        id
        groupId
        name
        faceMPa
        edgeMPa
        description
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      users {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      phone
      fax
      email
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      quoteInfo {
        greeting
        paymentMethod
        destination
        dueDate
        expiryDate
        description
      }
      bank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      taxRate
      maxInventoryM3
      prefix
      fiscalYearStartMonth
      salesCutoffDay
      revenueRecognitionCriterion
      mainSpeciesId
      mainSpeciesName
      mainSpeciesFaceMPa
      mainSpeciesEdgeMPa
      tenantId
      createdAt
      updatedAt
      holidays {
        items {
          id
          groupId
          name
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      mainSpecies {
        id
        groupId
        name
        faceMPa
        edgeMPa
        description
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      users {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      phone
      fax
      email
      logoUrl
      groupAddress {
        address
        street
        city
        state
        postalCode
        country
      }
      quoteInfo {
        greeting
        paymentMethod
        destination
        dueDate
        expiryDate
        description
      }
      bank {
        name
        branchName
        branchNumber
        type
        accountNumber
        swiftCode
      }
      packPhases {
        id
        name
        requiresPhoto
        showInKanBan
        numImgs
      }
      taxRate
      maxInventoryM3
      prefix
      fiscalYearStartMonth
      salesCutoffDay
      revenueRecognitionCriterion
      mainSpeciesId
      mainSpeciesName
      mainSpeciesFaceMPa
      mainSpeciesEdgeMPa
      tenantId
      createdAt
      updatedAt
      holidays {
        items {
          id
          groupId
          name
          date
          createdAt
          updatedAt
        }
        nextToken
      }
      mainSpecies {
        id
        groupId
        name
        faceMPa
        edgeMPa
        description
        active
        createdAt
        updatedAt
        materials {
          items {
            id
            groupId
            materialTypeId
            speciesId
            name
            purchaseUnit
            lossRate
            weight
            costCalculation
            defaultUnitCost
            unit
            length
            lengthApplicable
            lengthLocked
            lengthNominal
            width
            widthApplicable
            widthLocked
            widthNominal
            height
            heightApplicable
            heightLocked
            heightNominal
            active
            refBhhFace
            refBhhEdge
            z1402BeamOrder
            z1403BeamSupportOrder
            z1403BeamFaceOrder
            z1403BeamEdgeOrder
            z1410BeamSupportOrder
            z1410BeamFaceOrder
            z1410BeamEdgeOrder
            createdAt
            updatedAt
            vendors {
              nextToken
            }
            species {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            materialType {
              id
              groupId
              name
              description
              type
              isWood
              isPanel
              summaryReportTarget
              active
              createdAt
              updatedAt
            }
            purchasings {
              nextToken
            }
          }
          nextToken
        }
      }
      users {
        items {
          id
          userId
          groupId
          role
          createdAt
          updatedAt
          user {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          group {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
        }
        nextToken
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createUsersGroups = /* GraphQL */ `
  mutation CreateUsersGroups(
    $input: CreateUsersGroupsInput!
    $condition: ModelUsersGroupsConditionInput
  ) {
    createUsersGroups(input: $input, condition: $condition) {
      id
      userId
      groupId
      role
      createdAt
      updatedAt
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updateUsersGroups = /* GraphQL */ `
  mutation UpdateUsersGroups(
    $input: UpdateUsersGroupsInput!
    $condition: ModelUsersGroupsConditionInput
  ) {
    updateUsersGroups(input: $input, condition: $condition) {
      id
      userId
      groupId
      role
      createdAt
      updatedAt
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteUsersGroups = /* GraphQL */ `
  mutation DeleteUsersGroups(
    $input: DeleteUsersGroupsInput!
    $condition: ModelUsersGroupsConditionInput
  ) {
    deleteUsersGroups(input: $input, condition: $condition) {
      id
      userId
      groupId
      role
      createdAt
      updatedAt
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      group {
        id
        name
        phone
        fax
        email
        logoUrl
        groupAddress {
          address
          street
          city
          state
          postalCode
          country
        }
        quoteInfo {
          greeting
          paymentMethod
          destination
          dueDate
          expiryDate
          description
        }
        bank {
          name
          branchName
          branchNumber
          type
          accountNumber
          swiftCode
        }
        packPhases {
          id
          name
          requiresPhoto
          showInKanBan
          numImgs
        }
        taxRate
        maxInventoryM3
        prefix
        fiscalYearStartMonth
        salesCutoffDay
        revenueRecognitionCriterion
        mainSpeciesId
        mainSpeciesName
        mainSpeciesFaceMPa
        mainSpeciesEdgeMPa
        tenantId
        createdAt
        updatedAt
        holidays {
          items {
            id
            groupId
            name
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        mainSpecies {
          id
          groupId
          name
          faceMPa
          edgeMPa
          description
          active
          createdAt
          updatedAt
          materials {
            items {
              id
              groupId
              materialTypeId
              speciesId
              name
              purchaseUnit
              lossRate
              weight
              costCalculation
              defaultUnitCost
              unit
              length
              lengthApplicable
              lengthLocked
              lengthNominal
              width
              widthApplicable
              widthLocked
              widthNominal
              height
              heightApplicable
              heightLocked
              heightNominal
              active
              refBhhFace
              refBhhEdge
              z1402BeamOrder
              z1403BeamSupportOrder
              z1403BeamFaceOrder
              z1403BeamEdgeOrder
              z1410BeamSupportOrder
              z1410BeamFaceOrder
              z1410BeamEdgeOrder
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        users {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createTenant = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      name
      host
      mobileHost
      disableMultiTenant
      logoUrl
      address
      phone
      syncSFObject
      sfLoginUrl
      sfUsername
      sfPassword
      sfSecretToken
      projectFilters {
        name
        duration
        sort
        showProcesses
        showUnits
        showPhases
      }
      maxManagerLicense
      maxMobileLicense
      createdAt
      updatedAt
      accounts {
        items {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      contacts {
        items {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        nextToken
      }
      sfObjects {
        items {
          id
          name
          tenantId
          label
          sfObjectName
          editable
          condition
          fields {
            name
            label
            apiName
            type
            required
            unique
            primary
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      groups {
        items {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      name
      host
      mobileHost
      disableMultiTenant
      logoUrl
      address
      phone
      syncSFObject
      sfLoginUrl
      sfUsername
      sfPassword
      sfSecretToken
      projectFilters {
        name
        duration
        sort
        showProcesses
        showUnits
        showPhases
      }
      maxManagerLicense
      maxMobileLicense
      createdAt
      updatedAt
      accounts {
        items {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      contacts {
        items {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        nextToken
      }
      sfObjects {
        items {
          id
          name
          tenantId
          label
          sfObjectName
          editable
          condition
          fields {
            name
            label
            apiName
            type
            required
            unique
            primary
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      groups {
        items {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant(
    $input: DeleteTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    deleteTenant(input: $input, condition: $condition) {
      id
      name
      host
      mobileHost
      disableMultiTenant
      logoUrl
      address
      phone
      syncSFObject
      sfLoginUrl
      sfUsername
      sfPassword
      sfSecretToken
      projectFilters {
        name
        duration
        sort
        showProcesses
        showUnits
        showPhases
      }
      maxManagerLicense
      maxMobileLicense
      createdAt
      updatedAt
      accounts {
        items {
          id
          tenantId
          accountCode
          businessRegistrationNumber
          name
          abbreviation
          site
          billingAddress
          billingStreet
          billingCity
          billingState
          billingPostalCode
          billingCountry
          billingType
          fax
          phone
          customeCode
          cutoffDateText
          cutoffDateNumber
          paymentMonthText
          paymentMonthNumber
          paymentDateText
          paymentDateNumber
          invoiceUnit
          invoiceMethod
          active
          note
          accountId
          createdAt
          updatedAt
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      contacts {
        items {
          id
          accountId
          tenantId
          name
          firstName
          lastName
          suffix
          active
          email
          phone
          mobilePhone
          department
          title
          retired
          customeCode
          description
          contactId
          createdAt
          updatedAt
          account {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
        }
        nextToken
      }
      sfObjects {
        items {
          id
          name
          tenantId
          label
          sfObjectName
          editable
          condition
          fields {
            name
            label
            apiName
            type
            required
            unique
            primary
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          externalId
          name
          firstName
          lastName
          email
          showTutorial
          invited
          verified
          fontSize
          language
          receiveEmailNotification
          theme
          useMfa
          customId
          tenantId
          isAdmin
          createdAt
          updatedAt
          groups {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
          tenants {
            items {
              id
              userId
              tenantId
              isAdmin
              license
              hidden
              name
              firstName
              lastName
              customId
              active
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
      groups {
        items {
          id
          name
          phone
          fax
          email
          logoUrl
          groupAddress {
            address
            street
            city
            state
            postalCode
            country
          }
          quoteInfo {
            greeting
            paymentMethod
            destination
            dueDate
            expiryDate
            description
          }
          bank {
            name
            branchName
            branchNumber
            type
            accountNumber
            swiftCode
          }
          packPhases {
            id
            name
            requiresPhoto
            showInKanBan
            numImgs
          }
          taxRate
          maxInventoryM3
          prefix
          fiscalYearStartMonth
          salesCutoffDay
          revenueRecognitionCriterion
          mainSpeciesId
          mainSpeciesName
          mainSpeciesFaceMPa
          mainSpeciesEdgeMPa
          tenantId
          createdAt
          updatedAt
          holidays {
            items {
              id
              groupId
              name
              date
              createdAt
              updatedAt
            }
            nextToken
          }
          mainSpecies {
            id
            groupId
            name
            faceMPa
            edgeMPa
            description
            active
            createdAt
            updatedAt
            materials {
              nextToken
            }
          }
          users {
            items {
              id
              userId
              groupId
              role
              createdAt
              updatedAt
            }
            nextToken
          }
          tenant {
            id
            name
            host
            mobileHost
            disableMultiTenant
            logoUrl
            address
            phone
            syncSFObject
            sfLoginUrl
            sfUsername
            sfPassword
            sfSecretToken
            projectFilters {
              name
              duration
              sort
              showProcesses
              showUnits
              showPhases
            }
            maxManagerLicense
            maxMobileLicense
            createdAt
            updatedAt
            accounts {
              nextToken
            }
            contacts {
              nextToken
            }
            sfObjects {
              nextToken
            }
            users {
              nextToken
            }
            groups {
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createUsersTenants = /* GraphQL */ `
  mutation CreateUsersTenants(
    $input: CreateUsersTenantsInput!
    $condition: ModelUsersTenantsConditionInput
  ) {
    createUsersTenants(input: $input, condition: $condition) {
      id
      userId
      tenantId
      isAdmin
      license
      hidden
      name
      firstName
      lastName
      configuration {
        projectFilter {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
      }
      customId
      active
      createdAt
      updatedAt
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateUsersTenants = /* GraphQL */ `
  mutation UpdateUsersTenants(
    $input: UpdateUsersTenantsInput!
    $condition: ModelUsersTenantsConditionInput
  ) {
    updateUsersTenants(input: $input, condition: $condition) {
      id
      userId
      tenantId
      isAdmin
      license
      hidden
      name
      firstName
      lastName
      configuration {
        projectFilter {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
      }
      customId
      active
      createdAt
      updatedAt
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteUsersTenants = /* GraphQL */ `
  mutation DeleteUsersTenants(
    $input: DeleteUsersTenantsInput!
    $condition: ModelUsersTenantsConditionInput
  ) {
    deleteUsersTenants(input: $input, condition: $condition) {
      id
      userId
      tenantId
      isAdmin
      license
      hidden
      name
      firstName
      lastName
      configuration {
        projectFilter {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
      }
      customId
      active
      createdAt
      updatedAt
      user {
        id
        externalId
        name
        firstName
        lastName
        email
        showTutorial
        invited
        verified
        fontSize
        language
        receiveEmailNotification
        theme
        useMfa
        customId
        tenantId
        isAdmin
        createdAt
        updatedAt
        groups {
          items {
            id
            userId
            groupId
            role
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            group {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        tenant {
          id
          name
          host
          mobileHost
          disableMultiTenant
          logoUrl
          address
          phone
          syncSFObject
          sfLoginUrl
          sfUsername
          sfPassword
          sfSecretToken
          projectFilters {
            name
            duration
            sort
            showProcesses
            showUnits
            showPhases
          }
          maxManagerLicense
          maxMobileLicense
          createdAt
          updatedAt
          accounts {
            items {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
            nextToken
          }
          contacts {
            items {
              id
              accountId
              tenantId
              name
              firstName
              lastName
              suffix
              active
              email
              phone
              mobilePhone
              department
              title
              retired
              customeCode
              description
              contactId
              createdAt
              updatedAt
            }
            nextToken
          }
          sfObjects {
            items {
              id
              name
              tenantId
              label
              sfObjectName
              editable
              condition
              createdAt
              updatedAt
            }
            nextToken
          }
          users {
            items {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            nextToken
          }
          groups {
            items {
              id
              name
              phone
              fax
              email
              logoUrl
              taxRate
              maxInventoryM3
              prefix
              fiscalYearStartMonth
              salesCutoffDay
              revenueRecognitionCriterion
              mainSpeciesId
              mainSpeciesName
              mainSpeciesFaceMPa
              mainSpeciesEdgeMPa
              tenantId
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        tenants {
          items {
            id
            userId
            tenantId
            isAdmin
            license
            hidden
            name
            firstName
            lastName
            customId
            active
            createdAt
            updatedAt
            user {
              id
              externalId
              name
              firstName
              lastName
              email
              showTutorial
              invited
              verified
              fontSize
              language
              receiveEmailNotification
              theme
              useMfa
              customId
              tenantId
              isAdmin
              createdAt
              updatedAt
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
      tenant {
        id
        name
        host
        mobileHost
        disableMultiTenant
        logoUrl
        address
        phone
        syncSFObject
        sfLoginUrl
        sfUsername
        sfPassword
        sfSecretToken
        projectFilters {
          name
          duration
          sort
          showProcesses
          showUnits
          showPhases
        }
        maxManagerLicense
        maxMobileLicense
        createdAt
        updatedAt
        accounts {
          items {
            id
            tenantId
            accountCode
            businessRegistrationNumber
            name
            abbreviation
            site
            billingAddress
            billingStreet
            billingCity
            billingState
            billingPostalCode
            billingCountry
            billingType
            fax
            phone
            customeCode
            cutoffDateText
            cutoffDateNumber
            paymentMonthText
            paymentMonthNumber
            paymentDateText
            paymentDateNumber
            invoiceUnit
            invoiceMethod
            active
            note
            accountId
            createdAt
            updatedAt
            contacts {
              nextToken
            }
          }
          nextToken
        }
        contacts {
          items {
            id
            accountId
            tenantId
            name
            firstName
            lastName
            suffix
            active
            email
            phone
            mobilePhone
            department
            title
            retired
            customeCode
            description
            contactId
            createdAt
            updatedAt
            account {
              id
              tenantId
              accountCode
              businessRegistrationNumber
              name
              abbreviation
              site
              billingAddress
              billingStreet
              billingCity
              billingState
              billingPostalCode
              billingCountry
              billingType
              fax
              phone
              customeCode
              cutoffDateText
              cutoffDateNumber
              paymentMonthText
              paymentMonthNumber
              paymentDateText
              paymentDateNumber
              invoiceUnit
              invoiceMethod
              active
              note
              accountId
              createdAt
              updatedAt
            }
          }
          nextToken
        }
        sfObjects {
          items {
            id
            name
            tenantId
            label
            sfObjectName
            editable
            condition
            fields {
              name
              label
              apiName
              type
              required
              unique
              primary
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        users {
          items {
            id
            externalId
            name
            firstName
            lastName
            email
            showTutorial
            invited
            verified
            fontSize
            language
            receiveEmailNotification
            theme
            useMfa
            customId
            tenantId
            isAdmin
            createdAt
            updatedAt
            groups {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
            tenants {
              nextToken
            }
          }
          nextToken
        }
        groups {
          items {
            id
            name
            phone
            fax
            email
            logoUrl
            groupAddress {
              address
              street
              city
              state
              postalCode
              country
            }
            quoteInfo {
              greeting
              paymentMethod
              destination
              dueDate
              expiryDate
              description
            }
            bank {
              name
              branchName
              branchNumber
              type
              accountNumber
              swiftCode
            }
            packPhases {
              id
              name
              requiresPhoto
              showInKanBan
              numImgs
            }
            taxRate
            maxInventoryM3
            prefix
            fiscalYearStartMonth
            salesCutoffDay
            revenueRecognitionCriterion
            mainSpeciesId
            mainSpeciesName
            mainSpeciesFaceMPa
            mainSpeciesEdgeMPa
            tenantId
            createdAt
            updatedAt
            holidays {
              nextToken
            }
            mainSpecies {
              id
              groupId
              name
              faceMPa
              edgeMPa
              description
              active
              createdAt
              updatedAt
            }
            users {
              nextToken
            }
            tenant {
              id
              name
              host
              mobileHost
              disableMultiTenant
              logoUrl
              address
              phone
              syncSFObject
              sfLoginUrl
              sfUsername
              sfPassword
              sfSecretToken
              maxManagerLicense
              maxMobileLicense
              createdAt
              updatedAt
            }
          }
          nextToken
        }
      }
    }
  }
`;
